import React from "react";
import styled from "styled-components";

const TableWrapper = styled.table`
  border-collapse: separate;
  border-radius: 10px;
  border-spacing: 0;
  box-shadow: 0 0 5px ${({ theme }) => theme.colors.separator};
  width: 100%;
  margin: 36px 0;
  th {
    padding: 12px;
    background-color: ${({ theme }) => theme.colors.background};
    font-weight: normal;
    border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
  }

  th,
  td {
    line-height: 1.5;
    text-align: center;
  }

  td {
    background-color: ${({ theme }) => theme.colors.background};
  }

  td:first-child {
    font-weight: bold;
    text-align: left;
  }

  tbody tr:nth-child(odd) td {
    background-color: ${({ theme }) => theme.colors.backgroundSecond};
  }

  thead th:first-child {
    border-top-left-radius: 10px;
    text-align: left;
  }

  thead th:last-child {
    border-top-right-radius: 10px;
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }

  /* Stack rows vertically on small screens */
  @media (max-width: 620px) {
    /* Hide column labels */
    thead tr {
      position: absolute;
      top: -9999rem;
      left: -9999rem;
    }

    tbody tr td {
      border-radius: none;
      text-align: left;
    }

    tbody tr:first-child td:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    tbody tr:last-child td:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    /* Leave a space between table rows */
    tr + tr td:first-child {
      border-top: 1px solid ${({ theme }) => theme.colors.separator};
    }

    /* Get table cells to act like rows */
    tr,
    td {
      display: block;
    }

    td {
      border: none;
      /* Leave a space for data labels */
      padding-left: 50%;
    }
  }
`;

const Td = styled.td`
  padding: ${({ isEmpty }) => (isEmpty ? "0" : "12px")};
  @media (max-width: 620px) {
    :before {
      content: ${({ isChild }) => (isChild ? "" : "attr(data-label)")};
      display: inline-block;
      font-weight: bold;
      line-height: 1.5;
      margin-left: -100%;
      width: 100%;
      display: ${({ isEmpty }) => (isEmpty ? "none" : "inline-block")};
    }
  }
`;

const Table = ({ tableData }) => {
  return (
    <TableWrapper>
      <thead>
        <tr>
          {tableData.thead.map((data) => (
            <th key={data}>{data}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.tbody.map((data, indexTr) => (
          <tr key={indexTr}>
            {data.map((text, indexTd) => (
              <Td
                key={text + indexTd}
                data-label={tableData.thead[indexTd]}
                isEmpty={text === ""}
                isChild={
                  tableData.tbody[indexTr][indexTd - 1] === "" &&
                  tableData.tbody[indexTr][indexTd - 2] === ""
                }
              >
                {text}
              </Td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default Table;
