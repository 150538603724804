import React from "react";
import styled from "styled-components";

const InputStyled = styled.input`
  padding: 6px 12px;
  padding-left: ${({ readOnly }) => (readOnly ? "0" : null)};
  font-weight: ${({ readOnly }) => (readOnly ? "700" : null)};
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid
    ${({ readOnly }) => (readOnly ? "transparent" : "hsl(0, 0%, 80%)")};
  background-color: ${({ theme }) => theme.colors.backgroundSecond};
  width: 128px;
`;

const Input = (props) => {
  return <InputStyled {...props} />;
};

export default Input;
