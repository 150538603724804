import React from "react";


import Chitin from "../../assets/images/icons/gameIcons/Chitin.png";
import Fiber from "../../assets/images/icons/gameIcons/Fiber.png";
import Thatch from "../../assets/images/icons/gameIcons/Thatch.png";
import Wood from "../../assets/images/icons/gameIcons/Wood.png";
import Hide from "../../assets/images/icons/gameIcons/Hide.png";
import Flint from "../../assets/images/icons/gameIcons/Flint.png";
import Stone from "../../assets/images/icons/gameIcons/Stone.png";
import Keratin from "../../assets/images/icons/gameIcons/Keratin.png";
import Metal from "../../assets/images/icons/gameIcons/Metal.png";
import Crystal from "../../assets/images/icons/gameIcons/Crystal.png";
import Obsidian from "../../assets/images/icons/gameIcons/Obsidian.png";
import Oil from "../../assets/images/icons/gameIcons/Oil.png";
import Pelt from "../../assets/images/icons/gameIcons/Pelt.png";
import AmmoniteBlood from "../../assets/images/icons/gameIcons/Ammonite_Bile.png";
import OrganicPolymer from "../../assets/images/icons/gameIcons/Organic_Polymer.png";
import RareFlowers from "../../assets/images/icons/gameIcons/Rare_Flower.png";
import RareMushrooms from "../../assets/images/icons/gameIcons/Rare_Mushroom.png";
import Sap from "../../assets/images/icons/gameIcons/Sap.png";
import SilicaPearls from "../../assets/images/icons/gameIcons/Silica_Pearls.png";
import BlackPearl from "../../assets/images/icons/gameIcons/Black_Pearl.png";
import Amarberry from "../../assets/images/icons/gameIcons/Amarberry.png";
import Azulberry from "../../assets/images/icons/gameIcons/Azulberry.png";
import Tintoberry from "../../assets/images/icons/gameIcons/Tintoberry.png";
import Mejoberry from "../../assets/images/icons/gameIcons/Mejoberry.png";
import Narcoberry from "../../assets/images/icons/gameIcons/Narcoberry.png";
import Stimberry from "../../assets/images/icons/gameIcons/Stimberry.png";
import RawMeat from "../../assets/images/icons/gameIcons/Raw_Meat.png";
import GreenGem from "../../assets/images/icons/gameIcons/Green_Gem.png";
import BlueGem from "../../assets/images/icons/gameIcons/Blue_Gem_(Aberration).png";
import RedGem from "../../assets/images/icons/gameIcons/Red_Gem.png";
import CactusSap from "../../assets/images/icons/gameIcons/Cactus_Sap.png";
import Salt from "../../assets/images/icons/gameIcons/Salt.png";
import Sand from "../../assets/images/icons/gameIcons/Sand.png";
import Silk from "../../assets/images/icons/gameIcons/Silk.png";
import Sulfur from "../../assets/images/icons/gameIcons/Sulfur.png";
import Clay from "../../assets/images/icons/gameIcons/Clay.png";
import CementingPaste from "../../assets/images/icons/gameIcons/Cementing_Paste.png";
import Charcoal from "../../assets/images/icons/gameIcons/Charcoal.png";
import Gas from "../../assets/images/icons/gameIcons/Gasoline.png";
import Sparkpowder from "../../assets/images/icons/gameIcons/Sparkpowder.png";
import Gunpowder from "../../assets/images/icons/gameIcons/Gunpowder.png";
import MetalIngot from "../../assets/images/icons/gameIcons/Metal_Ingot.png";
import Electronics from "../../assets/images/icons/gameIcons/Electronics.png";
import Propellant from "../../assets/images/icons/gameIcons/Propellant_(Scorched_Earth).png";
import AnglerGel from "../../assets/images/icons/gameIcons/AnglerGel.png";
import GasBall from "../../assets/images/icons/gameIcons/Gas_Ball.png";
import DeathwormHorn from "../../assets/images/icons/gameIcons/Deathworm_Horn.png";
import Element from "../../assets/images/icons/gameIcons/Element.png";
import ElementOre from "../../assets/images/icons/gameIcons/Element_Ore.png";
import ElementShard from "../../assets/images/icons/gameIcons/Element_Shard.png";
import FungalWood from "../../assets/images/icons/gameIcons/Fungal_Wood_(Aberration).png";
import LeechBlood from "../../assets/images/icons/gameIcons/Leech_Blood.png";
import Stimulant from "../../assets/images/icons/gameIcons/Stimulant.png";
import Wool from "../../assets/images/icons/gameIcons/Wool.png";
import Mutagen from "../../assets/images/icons/gameIcons/Mutagen.png";
import Mutagel from "../../assets/images/icons/gameIcons/Mutagel.png";
import ElementDust from "../../assets/images/icons/gameIcons/Element_Dust.png";
import Narcotic from "../../assets/images/icons/gameIcons/Narcotic.png";
import Polymer from "../../assets/images/icons/gameIcons/Polymer.png";
import CookedMeat from "../../assets/images/icons/gameIcons/Cooked_Meat.png";
import Citronal from "../../assets/images/icons/gameIcons/Citronal.png";
import Longrass from "../../assets/images/icons/gameIcons/Longrass.png";
import Rockarrot from "../../assets/images/icons/gameIcons/Rockarrot.png";
import Savoroot from "../../assets/images/icons/gameIcons/Savoroot.png";
import SimpleBullet from "../../assets/images/icons/gameIcons/Simple_Bullet.png";
import BioToxin from "../../assets/images/icons/gameIcons/Bio_Toxin.png";
import GiantBeeHoney from "../../assets/images/icons/gameIcons/Giant_Bee_Honey.png";
import TranquilizerDart from "../../assets/images/icons/gameIcons/Tranquilizer_Dart.png";
import CookedPrimeMeat from "../../assets/images/icons/gameIcons/Cooked_Prime_Meat.png";
import AbsorbentSubstrate from "../../assets/images/icons/gameIcons/Absorbent_Substrate.png";
import StoneArrow from "../../assets/images/icons/gameIcons/Stone_Arrow.png";
import AutoTurret from "../../assets/images/icons/gameIcons/Auto_Turret.png";
import SpoiledMeat from "../../assets/images/icons/gameIcons/Spoiled_Meat.png";
import SimpleRifleAmmo from "../../assets/images/icons/gameIcons/Simple_Rifle_Ammo.png";
import RawPrimeMeat from "../../assets/images/icons/gameIcons/Raw_Prime_Meat.png";
// import  from "../../assets/images/icons/gameIcons/.png";
// import  from "../../assets/images/icons/gameIcons/.png";
// import  from "../../assets/images/icons/gameIcons/.png";
// import  from "../../assets/images/icons/gameIcons/.png";
// import  from "../../assets/images/icons/gameIcons/.png";
// import  from "../../assets/images/icons/gameIcons/.png";
// import  from "../../assets/images/icons/gameIcons/.png";
// import  from "../../assets/images/icons/gameIcons/.png";
// import  from "../../assets/images/icons/gameIcons/.png";




const options = [
  // {
  //   name: "",
  //   value: ,
  //   label: <img src={} alt="" />,
  //   hexagons: ,
  // },
  {
    name: "Fiber",
    value: Fiber,
    label: <img src={Fiber} alt="Fiber" />,
    hexagons: 0.24,
  },

  {
    name: "Thatch",
    value: Thatch,
    label: <img src={Thatch} alt="Thatch" />,
    hexagons: 0.4,
  },
  {
    name: "Wood",
    value: Wood,
    label: <img src={Wood} alt="Wood" />,
    hexagons: 0.5,
  },
  {
    name: "Hide",
    value: Hide,
    label: <img src={Hide} alt="Hide" />,
    hexagons: 1.2,
  },
  {
    name: "Flint",
    value: Flint,
    label: <img src={Flint} alt="Flint" />,
    hexagons: 0.36,
  },
  {
    name: "Stone",
    value: Stone,
    label: <img src={Stone} alt="Stone" />,
    hexagons: 0.8,
  },
  {
    name: "Keratin",
    value: Keratin,
    label: <img src={Keratin} alt="Keratin" />,
    hexagons: 2.4,
  },
  {
    name: "Chitin",
    value: Chitin,
    label: <img src={Chitin} alt="Chitin" />,
    hexagons: 2.4,
  },
  {
    name: "Metal",
    value: Metal,
    label: <img src={Metal} alt="Metal" />,
    hexagons: 15,
  },
  {
    name: "Crystal",
    value: Crystal,
    label: <img src={Crystal} alt="Crystal" />,
    hexagons: 11.5,
  },
  {
    name: "Obsidian",
    value: Obsidian,
    label: <img src={Obsidian} alt="Obsidian" />,
    hexagons: 36,
  },
  {
    name: "Oil",
    value: Oil,
    label: <img src={Oil} alt="Oil" />,
    hexagons: 6,
  },
  {
    name: "Pelt",
    value: Pelt,
    label: <img src={Pelt} alt="Pelt" />,
    hexagons: 2.6,
  },
  {
    name: "Ammonite Blood",
    value: AmmoniteBlood,
    label: <img src={AmmoniteBlood} alt="Ammonite Blood" />,
    hexagons: 350,
  },
  {
    name: "Organic Polymer",
    value: OrganicPolymer,
    label: <img src={OrganicPolymer} alt="Organic Polymer" />,
    hexagons: 100,
  },  
  {
    name: "Rare Flowers",
    value: RareFlowers,
    label: <img src={RareFlowers} alt="Rare Flowers" />,
    hexagons: 24,
  },  
  {
    name: "Rare Mushrooms",
    value: RareMushrooms,
    label: <img src={RareMushrooms} alt="Rare Mushrooms" />,
    hexagons: 24,
  },  
  {
    name: "Sap",
    value: Sap,
    label: <img src={Sap} alt="Sap" />,
    hexagons: 30,
  },  
  {
    name: "Silica Pearls",
    value: SilicaPearls,
    label: <img src={SilicaPearls} alt="Silica Pearls" />,
    hexagons: 25,
  },  
  {
    name: "Black Pearl",
    value: BlackPearl,
    label: <img src={BlackPearl} alt="Black Pearl" />,
    hexagons: 300,
  },  
  {
    name: "Amarberry",
    value: Amarberry,
    label: <img src={Amarberry} alt="Amarberry" />,
    hexagons: 0.2,
  },  
  {
    name: "Azulberry",
    value: Azulberry,
    label: <img src={Azulberry} alt="Azulberry" />,
    hexagons: 0.2,
  },  
  {
    name: "Tintoberry",
    value: Tintoberry,
    label: <img src={Tintoberry} alt="Tintoberry" />,
    hexagons: 0.2,
  },  
  {
    name: "Mejoberry",
    value: Mejoberry,
    label: <img src={Mejoberry} alt="Mejoberry" />,
    hexagons: 0.36,
  },  
  {
    name: "Narcoberry",
    value: Narcoberry,
    label: <img src={Narcoberry} alt="Narcoberry" />,
    hexagons: 4,
  },  
  {
    name: "Stimberry",
    value: Stimberry,
    label: <img src={Stimberry} alt="Stimberry" />,
    hexagons: 4,
  },  
  {
    name: "Raw Meat",
    value: RawMeat,
    label: <img src={RawMeat} alt="Raw Meat" />,
    hexagons: 1.25,
  },  
  {
    name: "Green Gem",
    value: GreenGem,
    label: <img src={GreenGem} alt="Green Gem" />,
    hexagons: 2.2,
  },  
  {
    name: "Blue Gem",
    value: BlueGem,
    label: <img src={BlueGem} alt="Blue Gem" />,
    hexagons: 4.75,
  },  
  {
    name: "Red Gem",
    value: RedGem,
    label: <img src={RedGem} alt="Red Gem" />,
    hexagons: 12.5,
  },  
  {
    name: "Cactus Sap",
    value: CactusSap,
    label: <img src={CactusSap} alt="Cactus Sap" />,
    hexagons: 0.48,
  },  
  {
    name: "Salt",
    value: Salt,
    label: <img src={Salt} alt="Salt" />,
    hexagons: 1.3,
  },  
  {
    name: "Sand",
    value: Sand,
    label: <img src={Sand} alt="Sand" />,
    hexagons: 0.56,
  },  
  {
    name: "Silk",
    value: Silk,
    label: <img src={Silk} alt="Silk" />,
    hexagons: 0.96,
  },
  {
    name: "Sulfur",
    value: Sulfur,
    label: <img src={Sulfur} alt="Sulfur" />,
    hexagons: 25,
  },
  {
    name: "Clay",
    value: Clay,
    label: <img src={Clay} alt="Clay" />,
    hexagons: 0.9,
  },
  {
    name: "Cementing Paste",
    value: CementingPaste,
    label: <img src={CementingPaste} alt="Cementing Paste" />,
    hexagons: 3,
  },
  {
    name: "Charcoal",
    value: Charcoal,
    label: <img src={Charcoal} alt="Charcoal" />,
    hexagons: 1.2,
  },
  {
    name: "Gas",
    value: Gas,
    label: <img src={Gas} alt="Gas" />,
    hexagons: 8.75,
  },
  {
    name: "Sparkpowder",
    value: Sparkpowder,
    label: <img src={Sparkpowder} alt="Sparkpowder" />,
    hexagons: 1,
  },
  {
    name: "Gunpowder",
    value: Gunpowder,
    label: <img src={Gunpowder} alt="Gunpowder" />,
    hexagons: 7.5,
  },
  {
    name: "Metal Ingot",
    value: MetalIngot,
    label: <img src={MetalIngot} alt="Metal Ingot" />,
    hexagons: 39,
  },
  {
    name: "Electronics",
    value: Electronics,
    label: <img src={Electronics} alt="Electronics" />,
    hexagons: 140,
  },
  {
    name: "Propellant",
    value: Propellant,
    label: <img src={Propellant} alt="Propellant" />,
    hexagons: 38,
  },
 

  // { name: "Thatch", value: Thatch, label: <img src={Thatch} alt="Thatch" /> },
  // { name: "Wood", value: Wood, label: <img src={Wood} alt="Wood" /> },

  { name: "Angler Gel", value: AnglerGel, label: <img src={AnglerGel} alt="Angler Gel" /> },
  { name: "Gas Ball", value: GasBall, label: <img src={GasBall} alt="Gas Ball" /> },
  { name: "Deathworm Horn", value: DeathwormHorn, label: <img src={DeathwormHorn} alt="Deathworm Horn" /> },
  { name: "Element", value: Element, label: <img src={Element} alt="Element" /> },
  { name: "Element Ore", value: ElementOre, label: <img src={ElementOre} alt="Element Ore" /> },
  { name: "Element Shard", value: ElementShard, label: <img src={ElementShard} alt="Element Shard" /> },
  { name: "Fungal Wood", value: FungalWood, label: <img src={FungalWood} alt="Fungal Wood" /> },
  { name: "Leech Blood", value: LeechBlood, label: <img src={LeechBlood} alt="Leech Blood" /> },
  { name: "Stimulant", value: Stimulant, label: <img src={Stimulant} alt="Stimulant" /> },
  { name: "Wool", value: Wool, label: <img src={Wool} alt="Wool" /> },
  { name: "Mutagen", value: Mutagen, label: <img src={Mutagen} alt="Mutagen" /> },
  { name: "Mutagel", value: Mutagel, label: <img src={Mutagel} alt="Mutagel" /> },
  { name: "Element Dust", value: ElementDust, label: <img src={ElementDust} alt="Element Dust" /> },
  { name: "Narcotic", value: Narcotic, label: <img src={Narcotic} alt="Narcotic" /> },
  { name: "Polymer", value: Polymer, label: <img src={Polymer} alt="Polymer" /> },
  { name: "Cooked Meat", value: CookedMeat, label: <img src={CookedMeat} alt="Cooked Meat" /> },
  { name: "Longrass", value: Longrass, label: <img src={Longrass} alt="Longrass" /> },
  { name: "Citronal", value: Citronal, label: <img src={Citronal} alt="Citronal" /> },
  { name: "Savoroot", value: Savoroot, label: <img src={Savoroot} alt="Savoroot" /> },
  { name: "Rockarrot", value: Rockarrot, label: <img src={Rockarrot} alt="Rockarrot" /> },
  { name: "Simple Bullet", value: SimpleBullet, label: <img src={SimpleBullet} alt="Simple Bullet" /> },
  { name: "Bio Toxin", value: BioToxin, label: <img src={BioToxin} alt="Bio Toxin" /> },
  { name: "Giant Bee Honey", value: GiantBeeHoney, label: <img src={GiantBeeHoney} alt="Giant Bee Honey" /> },
  { name: "Tranquilizer Dart", value: TranquilizerDart, label: <img src={TranquilizerDart} alt="Tranquilizer Dart" /> },
  { name: "Cooked Prime Meat", value: CookedPrimeMeat, label: <img src={CookedPrimeMeat} alt="Cooked Prime Meat" /> },
  { name: "Absorbent Substrate", value: AbsorbentSubstrate, label: <img src={AbsorbentSubstrate} alt="Absorbent Substrate" /> },
  { name: "Stone Arrow", value: StoneArrow, label: <img src={StoneArrow} alt="Stone Arrow" /> },
  { name: "Auto Turret", value: AutoTurret, label: <img src={AutoTurret} alt="Auto Turret" /> },
  { name: "Spoiled Meat", value: SpoiledMeat, label: <img src={SpoiledMeat} alt="Spoiled Meat" /> },
  { name: "Simple Rifle Ammo", value: SimpleRifleAmmo, label: <img src={SimpleRifleAmmo} alt="Simple Rifle Ammo" /> },
  { name: "Raw Prime Meat", value: RawPrimeMeat, label: <img src={RawPrimeMeat} alt="Raw Prime Meat" /> },
  // { name: "", value: , label: <img src={} alt="" /> },
  // { name: "", value: , label: <img src={} alt="" /> },
  // { name: "", value: , label: <img src={} alt="" /> },
  // { name: "", value: , label: <img src={} alt="" /> },
    // { name: "", value: , label: <img src={} alt="" /> },
  // { name: "", value: , label: <img src={} alt="" /> },
    // { name: "", value: , label: <img src={} alt="" /> },
  // { name: "", value: , label: <img src={} alt="" /> },
    // { name: "", value: , label: <img src={} alt="" /> },
  // { name: "", value: , label: <img src={} alt="" /> },
    // { name: "", value: , label: <img src={} alt="" /> },
  // { name: "", value: , label: <img src={} alt="" /> },



];


export default options;
