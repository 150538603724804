import styled from "styled-components";

export const H2 = styled.h2`
  font-size: 32px;
  margin: 80px 0 24px 0;
  font-weight: 900;
`;

export const H3 = styled.h3`
  font-size: 24px;
  margin: 48px 0 24px 0;
  font-weight: 900;
`;
