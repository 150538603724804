import React, { useState } from "react";
import styled from "styled-components";

const ImageWrapperStyled = styled.div`
  width: 80%;
  margin: 24px auto;
  img {
    border-radius: 6px;
    width: 100%;
  }
  button {
    width: 100%;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`;
const ImageDescription = styled.div`
  margin: 14px 0 57px 0;
  padding: 0 8px;
  text-align: end;
  font-size: 14px;
  color: #6e7076;
  ::before {
    content: "— ";
  }
`;

const ImageBigWrapper = styled.div`
  z-index: 999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-resp-image-wrapper {
    width: 90vw;
    .gatsby-resp-image-background-image {
      background: none !important;
    }
    .gatsby-resp-image-image {
      width: 24px;
    }
  }
  img {
    max-width: 80vw;
    max-height: 80vh;
    background-color: rgba(0, 0, 0, 0.4);
    object-fit: contain;
    z-index: 1;
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 50%;
  }
`;

const ClickCapture = styled.button`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ImageBig = ({ children, setIsOpen }) => {
  return (
    <ImageBigWrapper>
      <ClickCapture onClick={() => setIsOpen(false)} />
      {children}
    </ImageBigWrapper>
  );
};

const ImageWrapper = ({ children, description, style }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isBrowser = typeof document !== "undefined";
  if (isBrowser) {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }

  return (
    <ImageWrapperStyled style={style}>
      <button onClick={() => setIsOpen(true)}>{children}</button>
      <ImageDescription>{description}</ImageDescription>
      {isOpen ? <ImageBig setIsOpen={setIsOpen}>{children}</ImageBig> : null}
    </ImageWrapperStyled>
  );
};

export default ImageWrapper;
