import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Select from "react-select";

import resources from "./resList";
import Input from "../Inputs/Input";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import deleteIco from "../../assets/images/icons/close.svg";
import hexagonIco from "../../assets/images/icons/gameIcons/Hexagon_Icon.png";

const ResourceWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.separator};
  background-color: ${({ theme }) => theme.colors.backgroundSecond};
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 28px 36px;
  display: flex;
  flex-direction: column;
  @media (max-width: 400px) {
    padding: 28px 12px;
    margin: 0 -36px;
  }
`;

const SelectStyled = styled(Select)`
  color: inherit;
  width: 50%;
  .react__input {
    color: ${({ theme }) => theme.colors.text};
  }
  .react__single-value {
    color: inherit;
  }
  .react__control,
  .react__menu-list {
    background-color: ${({ theme }) => theme.colors.backgroundSecond};
    color: inherit;
  }
  .react__option {
    :hover {
      background-color: ${({ theme }) => theme.colors.backgroundFocus};
    }
    &--is-focused {
      background-color: ${({ theme }) => theme.colors.backgroundFocus};
    }
  }
  img {
    width: 18px;
  }
`;

const SummaryWrapper = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.separator};
`;
const ItemsWrapper = styled.li`
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  padding: 6px;
  cursor: pointer;
  user-select: none;
  position: relative;
  img {
    height: 30px;
    margin-bottom: 8px;
  }
`;

const ResourceLiWrapper = styled.li`
  display: flex;
  margin: 12px 0;
  width: 100%;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 16px;
  button {
    margin-left: auto;
    img {
      filter: invert(${({ theme }) => theme.filters.invert})
        grayscale(${({ theme }) => theme.filters.invert});
    }
  }
  input {
    width: 20%;
    margin: 0 12px;
  }
  :last-child {
    button {
      display: none;
    }
  }
`;
const Items = ({ element, multiplier, onClick }) => {
  multiplier = typeof multiplier === "undefined" ? 1 : multiplier;
  return (
    <ItemsWrapper title={element.item.name} onClick={onClick}>
      {element.isHex ? (
        <img src={hexagonIco} alt={element.item.name} draggable="false" />
      ) : (
        <img
          src={element.item.value}
          alt={element.item.name}
          draggable="false"
        />
      )}

      {element.isHex
        ? (
            Math.round(
              (element.item.hexagons * multiplier * element.quantity +
                Number.EPSILON) *
                100
            ) / 100
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        : (
            Math.round((element.quantity * multiplier + Number.EPSILON) * 100) /
            100
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
    </ItemsWrapper>
  );
};

const Summary = () => {
  const [state, dispatch] = useContext(GlobalStateContext);
  const data = JSON.parse(JSON.stringify(state.data));
  const items = [];
  let hex = 0;
  for (const [, { recipe, multiplier }] of Object.entries(data)) {
    recipe.forEach((element) => {
      element.quantity *= multiplier;
      const indexExist = items.findIndex(
        (e) => e.item.name === element.item.name
      );
      if (indexExist === -1 && !element.isHex) {
        items.push(element);
      } else if (indexExist !== -1 && !element.isHex) {
        items[indexExist].quantity += element.quantity;
      } else {
        hex += element.item.hexagons * element.quantity;
      }
    });
  }

  const hexagons = (data) => {
    if (!("hexagons" in data.item)) {
      return "There are no hexagons";
    }
    const items = { ...state.data };
    for (const [key, value] of Object.entries(items)) {
      const indexOfItem = value.recipe.findIndex(
        (x) => x.item.name === data.item.name
      );
      if (indexOfItem >= 0) {
        items[key].recipe[indexOfItem].isHex = true;
      }
    }
    dispatch({ type: "CHANGE_DATA", value: items });
  };

  return (
    <SummaryWrapper>
      <h3 style={{ width: "100%", margin: "24px 0" }}>Summary</h3>
      <ul style={{ display: "flex", flexWrap: "wrap" }}>
        {items.map((element) => (
          <Items
            key={Math.random()}
            element={element}
            onClick={() => {
              hexagons(element);
            }}
          />
        ))}
        <ItemsWrapper>
          <img src={hexagonIco} alt="hexagon" />
          {Math.round((hex + Number.EPSILON) * 100) / 100}
        </ItemsWrapper>
      </ul>
    </SummaryWrapper>
  );
};

const ResourceLi = ({ index }) => {
  const [state, dispatch] = useContext(GlobalStateContext);
  const [items, setItems] = useState({ recipe: [] });
  const [multiplier, setMultiplier] = useState(1);
  const [isRemoved, remove] = useState(false);

  const filteredResources = () => {
    const temp = [...resources];
    const used = [];
    for (const [, { value }] of Object.entries(state.data)) {
      if (typeof value !== "undefined") {
        used.push(value);
      }
    }
    return temp.filter((e) => used.indexOf(e.value) === -1);
  };

  useEffect(() => {
    items.multiplier = multiplier;
    dispatch({ type: "SET_DATA", index, value: items });
  }, [items, index, dispatch, multiplier]);

  const hexagons = (data) => {
    if (!("hexagons" in data.item)) {
      return "There are no hexagons";
    }
    items.multiplier = multiplier;
    const clickedItem =
      items.recipe[
        items.recipe.findIndex((e) => e.item.name === data.item.name)
      ];
    if (clickedItem.hasOwnProperty("isHex")) {
      clickedItem.isHex = !clickedItem.isHex;
    } else {
      clickedItem.isHex = true;
    }
    dispatch({ type: "SET_DATA", index, value: items });
  };

  return isRemoved ? null : (
    <ResourceLiWrapper>
      <SelectStyled
        classNamePrefix="react"
        placeholder="item"
        options={filteredResources()}
        onChange={(value) => {
          value.index = index;
          return setItems(value);
        }}
        index={index}
      />
      <Input
        type="number"
        min={0}
        onChange={(e) => setMultiplier(parseInt(e.target.value))}
        value={multiplier}
      />
      <button
        onClick={() => {
          dispatch({ type: "REMOVE_DATA", value: `item${index}` });
          dispatch({ type: "REMOVE_ELEMENTS", value: index });
          remove(true);
        }}
      >
        <img src={deleteIco} alt="delete" />
      </button>
      <ul
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginTop: "16px",
          flexWrap: "wrap",
        }}
      >
        {items.recipe
          ? items.recipe.map((element, index) => (
              <Items
                element={element}
                multiplier={multiplier}
                key={Math.random()}
                onClick={() => hexagons(element)}
              />
            ))
          : null}
      </ul>
    </ResourceLiWrapper>
  );
};

const Resource = () => {
  const [state, dispatch] = useContext(GlobalStateContext);
  let numberOfElements;
  useEffect(() => {
    dispatch({
      type: "RESET_ELEMENTS",
    });
  }, [dispatch]);
  try {
    numberOfElements = state.elements.length;
  } catch (error) {}

  useEffect(() => {
    if (numberOfElements === 0) {
      dispatch({
        type: "SET_ELEMENTS",
        value: <ResourceLi index={1} key={Math.random()} />,
      });
    }
  }, [dispatch, numberOfElements]);

  useEffect(() => {
    try {
      if (
        numberOfElements > 0 &&
        state.data[
          Object.keys(state.data)[numberOfElements - 1]
        ].hasOwnProperty("value")
      ) {
        dispatch({
          type: "SET_ELEMENTS",
          value: (
            <ResourceLi
              index={state.elements[numberOfElements - 1].props.index + 1}
              key={Math.random()}
            />
          ),
        });
      }
    } catch (error) {}
  }, [numberOfElements, state.data, state.elements, dispatch]);
  return (
    <ResourceWrapper>
      Click on resource to use hexagon points
      <ul>{state.elements}</ul>
      <Summary />
    </ResourceWrapper>
  );
};

export default Resource;
