import styled from "styled-components";

export const Ul = styled.ul`
  padding-left: 36px;
  margin-bottom: 16px;
`;

export const Ol = styled.ol`
  padding-left: 36px;
  margin-bottom: 16px;
`;

export const Li = styled.li`
  margin: 6px 0;
`;
