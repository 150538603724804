import React, { useState } from "react";
import styled from "styled-components";

import Input from "../Inputs/Input";

const ArmorWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.separator};
  background-color: ${({ theme }) => theme.colors.backgroundSecond};
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 28px 36px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr;
  gap: 12px 6px;
  grid-template-areas:
    "title1 title2 title4"
    "craftLvl armor maxArmor"
    ". title3 title5"
    ". dura maxDura"
    ". . title6"
    ". . maxBonus";

  @media (max-width: 630px) {
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr;
    gap: 8px 6px;
    grid-template-areas:
      "title1"
      "craftLvl"
      "title2"
      "armor"
      "title3"
      "dura"
      "title4"
      "maxArmor"
      "title5"
      "maxDura"
      "title6"
      "maxBonus";
  }
`;

const Armor = () => {
  const [craftLvl, setCraftLvl] = useState(1);
  const [armor, setArmor] = useState(0);
  const [dura, setDura] = useState(0);
  const maxCraftingBonus = parseFloat((craftLvl - 1) / 2 / 100);
  return (
    <ArmorWrapper>
      <div style={{ gridArea: "title1" }}>Crafter level:</div>
      <Input
        style={{ gridArea: "craftLvl" }}
        placeholder="1"
        onChange={(e) => setCraftLvl(e.target.value)}
        value={craftLvl}
        min={1}
        type="number"
      />
      <div style={{ gridArea: "title2" }}>Blueprint armor:</div>
      <Input
        style={{ gridArea: "armor" }}
        placeholder="0"
        onChange={(e) => setArmor(e.target.value)}
        value={armor}
        type="number"
      />
      <div style={{ gridArea: "title3" }}>Blueprint dura:</div>
      <Input
        style={{ gridArea: "dura" }}
        placeholder="0"
        onChange={(e) => setDura(e.target.value)}
        value={dura}
        type="number"
      />
      <div style={{ gridArea: "title4" }}>Max armor:</div>
      <Input
        style={{ gridArea: "maxArmor" }}
        placeholder="0"
        value={((armor - 100) * (maxCraftingBonus + 1) + 100).toFixed(1)}
        readOnly
        type="number"
      />
      <div style={{ gridArea: "title5" }}>Max dura:</div>
      <Input
        style={{ gridArea: "maxDura" }}
        placeholder="0"
        value={((dura - 120) * (maxCraftingBonus + 1) + 120).toFixed(1)}
        readOnly
        type="number"
      />
      <div style={{ gridArea: "title6" }}>Max crafting bonus:</div>
      <Input
        style={{ gridArea: "maxBonus" }}
        placeholder="0"
        value={`${(maxCraftingBonus * 100).toFixed(1)}%`}
        readOnly
      />
    </ArmorWrapper>
  );
};

export default Armor;
