import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";

import Input from "../Inputs/Input";
import items from "./items";

const BlueprintsWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.separator};
  background-color: ${({ theme }) => theme.colors.backgroundSecond};
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 28px 36px;
  display: grid;
  grid-template-columns: 1fr 1fr 0.3fr 0.5fr;
  grid-template-rows: auto 1fr 1fr 1fr 1fr 1fr;
  gap: 12px 6px;
  grid-template-areas:
    "title1 title2 title3 title3"
    ". v1 result1 ico1"
    ". v2 result2 ico2"
    "itemsNumber v3 result3 ico3"
    ". v4 result4 ico4"
    ". v5 result5 ico5";
  @media (min-width: 1200px) and (max-width: 1315px) {
    padding: 28px 8px;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "title1 title1"
      "itemsNumber itemsNumber"
      "title2 title2"
      "v1 v1"
      "v2 v2"
      "v3 v3"
      "v4 v4"
      "v5 v5"
      "title3 title3"
      "result1 ico1"
      "result2 ico2"
      "result3 ico3"
      "result4 ico4"
      "result5 ico5";
  }
`;

const SelectStyled = styled(Select)`
  width: 86px;
  grid-area: ${({ number }) => `ico${number}`};
  .react__control,
  .react__menu-list {
    background-color: ${({ theme }) => theme.colors.backgroundSecond};
  }
  img {
    width: 18px;
  }
`;

const Row = ({ itemsNumber, number }) => {
  const [blueprintRes, setBlueprintRes] = useState(0);

  const onChangeHandler = (e) => {
    const value = e.target.value.replace(/[^\d]/, "");
    setBlueprintRes(value);
  };

  return (
    <>
      <Input
        onChange={onChangeHandler}
        value={blueprintRes}
        style={{ gridArea: `v${number}` }}
        placeholder="0"
        type="number"
      />
      <span
        value={itemsNumber * blueprintRes}
        style={{
          gridArea: `result${number}`,
          alignSelf: "center",
          fontWeight: 700,
          marginRight: "16px",
        }}
        role="textbox"
      >
        {itemsNumber * blueprintRes}
      </span>
      <SelectStyled
        classNamePrefix="react"
        placeholder="ico"
        options={items}
        isSearchable={false}
        number={number}
      />
    </>
  );
};

const calcutalorNames = [1, 2, 3, 4, 5];

const Blueprints = () => {
  const [itemsNumber, setItemsNumber] = useState(0);

  const onChangeHandler = (e) => {
    const value = e.target.value.replace(/[^\d]/, "");
    setItemsNumber(value);
  };

  return (
    <BlueprintsWrapper>
      <div style={{ gridArea: "title1" }}>
        How many items you want to craft?
      </div>
      <div style={{ gridArea: "title2" }}>Blueprint resources</div>
      <div style={{ gridArea: "title3" }}>Resources</div>
      {calcutalorNames.map((number) => (
        <Row key={number} number={number} itemsNumber={itemsNumber} />
      ))}
      <Input
        onChange={onChangeHandler}
        value={itemsNumber}
        style={{ gridArea: "itemsNumber" }}
        placeholder="0"
        type="number"
      />
    </BlueprintsWrapper>
  );
};

export default Blueprints;
