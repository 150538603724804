import React, { useRef, useState } from "react";
import styled from "styled-components";

import copyImg from "../assets/images/icons/copy.svg";

const TextCopyWrapper = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.separator};
  border-radius: 5px;
  position: relative;
  border: ${({ copyState }) =>
    copyState ? "2px solid rgba(0, 204, 0, 0.3)" : "2px solid transparent"};
  word-wrap: break-word;
  margin: 24px 0;
`;

const CopyButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
`;

const CopyIco = styled.img`
  width: 12px;
  margin-right: 6px;
  filter: invert(${({ theme }) => theme.filters.invert})
    grayscale(${({ theme }) => theme.filters.invert});
`;

const EasyTextCopy = ({ children }) => {
  const textToCopy = useRef();
  const [copyState, setCopyState] = useState(false);
  const copyCodeToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = textToCopy.current.textContent;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopyState(true);
  };

  return (
    <TextCopyWrapper copyState={copyState}>
      <div ref={textToCopy}>{children}</div>
      <CopyButton onClick={() => copyCodeToClipboard()}>
        <CopyIco src={copyImg} alt="copy" />
        Copy
      </CopyButton>
    </TextCopyWrapper>
  );
};

export default EasyTextCopy;
