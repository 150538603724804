import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import Card, { CardContainer } from "./Card";

const PostListWrapper = styled.div``;

const filterData = (data, filter) => {
  const {
    allMdx: { nodes: postData },
  } = data;
  const filtered = postData.filter(
    (element) =>
      element.frontmatter.path.split("/")[1] === filter.split("/")[1] &&
      element.frontmatter.path !== filter
  );
  return filtered;
};

const PostList = () => {
  const isBrowser = typeof window !== "undefined";
  const url = isBrowser ? window.location.pathname : "";
  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: frontmatter___order, order: ASC }) {
        nodes {
          frontmatter {
            excerpt
            seoTitle
            path
            postImage {
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <PostListWrapper>
      <CardContainer>
        {isBrowser
          ? filterData(data, url).map(({ frontmatter }) => (
              <Card key={frontmatter.seoTitle} data={frontmatter} />
            ))
          : null}
      </CardContainer>
    </PostListWrapper>
  );
};

export default PostList;
