import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const StyledLink = styled(Link)`
  color: #5333ed;
  text-decoration: none;
  font-size: inherit;
  background: linear-gradient(to right, #5333ed, #5333ed),
    linear-gradient(to right, transparent, transparent);
  background-size: 100% 0.1em, 0 0.1em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;

  :hover,
  :focus {
    background-size: 0 0.1em, 100% 0.1em;
  }
`;

const StyledA = styled.a`
  color: #5333ed;
  text-decoration: none;
  font-size: inherit;
  background: linear-gradient(to right, #5333ed, #5333ed),
    linear-gradient(to right, transparent, transparent);
  background-size: 100% 0.1em, 0 0.1em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;

  :hover,
  :focus {
    background-size: 0 0.1em, 100% 0.1em;
  }
`;

const Ref = ({ href, to, children }) => {
  return (
    <>
      {typeof to !== "undefined" ? (
        <StyledLink to={to}>{children}</StyledLink>
      ) : (
        <StyledA href={href} target="_blank" rel="noreferrer">
          {children}
        </StyledA>
      )}
    </>
  );
};

export default Ref;
