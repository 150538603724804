import React, { useState } from "react";
import styled from "styled-components";

import Input from "../Inputs/Input";

const WeaponWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.separator};
  background-color: ${({ theme }) => theme.colors.backgroundSecond};
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 28px 36px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.3fr 1fr 0.3fr 1fr;
  gap: 12px 6px;
  grid-template-areas:
    "title1 title3"
    "craftLvl maxBonus"
    "title2 title4"
    "damage maxDamage";
  @media (max-width: 630px) {
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr;
    gap: 8px 6px;
    grid-template-areas:
      "title1"
      "craftLvl"
      "title2"
      "damage"
      "title3"
      "maxBonus"
      "title4"
      "maxDamage";
  }
`;

const Weapon = () => {
  const [craftLvl, setCraftLvl] = useState(1);
  const [damage, setDamage] = useState(100);

  const maxCraftingBonus = parseFloat((craftLvl - 1) / 2 / 100);
  return (
    <WeaponWrapper>
      <div style={{ gridArea: "title1" }}>Crafter level:</div>
      <Input
        style={{ gridArea: "craftLvl" }}
        placeholder="1"
        onChange={(e) => setCraftLvl(e.target.value)}
        value={craftLvl}
        min={1}
        type="number"
      />
      <div style={{ gridArea: "title2" }}>Blueprint weapon damage:</div>
      <Input
        style={{ gridArea: "damage" }}
        placeholder="100"
        onChange={(e) => setDamage(e.target.value)}
        value={damage}
        min={100}
        type="number"
      />
      <div style={{ gridArea: "title3" }}>Max crafting bonus:</div>
      <Input
        style={{ gridArea: "maxBonus" }}
        placeholder="0"
        value={`${(maxCraftingBonus * 100).toFixed(1)}%`}
        readOnly
      />
      <div style={{ gridArea: "title4" }}>Max possible weapon damage:</div>
      <Input
        style={{ gridArea: "maxDamage" }}
        placeholder="0"
        value={`${parseFloat(
          (damage - 100) * (maxCraftingBonus + 1) + 100
        ).toFixed(1)}%`}
        readOnly
      />
    </WeaponWrapper>
  );
};

export default Weapon;
