import React, { useState } from "react";
import styled from "styled-components";

import Input from "../Inputs/Input";

const SaddleWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.separator};
  background-color: ${({ theme }) => theme.colors.backgroundSecond};
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 28px 36px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr;
  gap: 12px 6px;
  grid-template-areas:
    "title1 title4"
    "craftLvl maxSaddle"
    "title2 title5"
    "armor maxBonus"
    "title3 ."
    "saddleArmor .";

  @media (max-width: 630px) {
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr;
    gap: 8px 6px;
    grid-template-areas:
      "title1"
      "craftLvl"
      "title2"
      "armor"
      "title3"
      "saddleArmor"
      "title4"
      "maxSaddle"
      "title5"
      "maxBonus";
  }
`;

const Saddle = () => {
  const [craftLvl, setCraftLvl] = useState(1);
  const [armor, setArmor] = useState(1);
  const [saddleArmor, setSaddleArmor] = useState(1);

  const maxCraftingBonus = parseFloat((craftLvl - 1) / 2 / 100);
  return (
    <SaddleWrapper>
      <div style={{ gridArea: "title1" }}>Crafter level:</div>
      <Input
        style={{ gridArea: "craftLvl" }}
        placeholder="1"
        onChange={(e) => setCraftLvl(e.target.value)}
        value={craftLvl}
        min={1}
        type="number"
      />
      <div style={{ gridArea: "title2" }}>Blueprint armor:</div>
      <Input
        style={{ gridArea: "armor" }}
        placeholder="1"
        onChange={(e) => setArmor(e.target.value)}
        value={armor}
        min={1}
        type="number"
      />
      <div style={{ gridArea: "title3" }}>Prim saddle armor:</div>
      <Input
        style={{ gridArea: "saddleArmor" }}
        placeholder="1"
        onChange={(e) => setSaddleArmor(e.target.value)}
        value={saddleArmor}
        min={20}
        type="number"
      />
      <div style={{ gridArea: "title4" }}>Max possible saddle armor:</div>
      <Input
        style={{ gridArea: "maxSaddle" }}
        placeholder="0"
        value={parseInt(
          (armor - saddleArmor) * (maxCraftingBonus + 1) + parseInt(saddleArmor)
        )}
        readOnly
        type="number"
      />
      <div style={{ gridArea: "title5" }}>Max crafting bonus:</div>
      <Input
        style={{ gridArea: "maxBonus" }}
        placeholder="0"
        value={`${(maxCraftingBonus * 100).toFixed(1)}%`}
        readOnly
      />
    </SaddleWrapper>
  );
};

export default Saddle;
