import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "./Layout";
import Text from "../components/Text/Text";
import Seo from "../components/Seo";
import Copy from "../components/EasyTextCopy";
import Download from "../components/Buttons/Download";
import ImageWrapper from "../components/ImageWrapper";
import PostList from "../components/PostList";
import PostTitle from "../components/PostTitle";
import ReactCompareImage from "react-compare-image";
import Ref from "../components/Text/Ref";
import { H2, H3 } from "../components/Text/Header";
import { Ul, Ol, Li } from "../components/Text/List";
import Blueprint from "../components/Calculators/Blueprints";
import Armor from "../components/Calculators/Armor";
import Saddle from "../components/Calculators/Saddle";
import Weapon from "../components/Calculators/Weapon";
import Table from "../components/Table";
import Resource from "../components/Calculators/Resource";
import GoogleAds from "../components/GoogleAds";

const shortcodes = {
  Text,
  Copy,
  Download,
  ImageWrapper,
  PostList,
  PostTitle,
  ReactCompareImage,
  Ref,
  H2,
  Ul,
  Ol,
  Li,
  H3,
  Blueprint,
  Armor,
  Saddle,
  Weapon,
  Table,
  Resource,
  GoogleAds,
};

const Main = styled.main`
  .gatsby-resp-image-image {
    border-radius: 6px;
  }
`;

export default function PageTemplate({ data, pageContext }) {
  return (
    <>
      <Seo
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.excerpt}
        ogImage={data.mdx.frontmatter.postImage}
      />
      <Layout pageContext={pageContext}>
        <Main style={{ padding: "36px" }}>
          <Text>
            <MDXProvider components={shortcodes}>
              <MDXRenderer frontmatter={data.mdx.frontmatter}>
                {data.mdx.body}
              </MDXRenderer>
            </MDXProvider>
          </Text>
        </Main>
      </Layout>
    </>
  );
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        excerpt
        postImage {
          publicURL
        }
      }
    }
  }
`;
