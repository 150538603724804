import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

const PostTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 48px;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1000px) {
    flex-direction: row;
  }
`;

const Title = styled.div`
  font-size: 44px;
  margin-bottom: 16px;
  font-weight: 600;
`;

const Excerpt = styled.div`
  font-size: 16px;
  @media (max-width: 1000px) {
    margin-bottom: 24px;
  }
`;

const TextWrapper = styled.div`
  flex: 0.5;
  @media (min-width: 1280px) {
    flex: 1.2;
  }
`;

const PostImage = styled(Img)`
  flex: 1;
  min-width: 280px;
  border-radius: 10px;
  @media (min-width: 1000px) {
    margin-left: 24px;
  }
`;

const filterData = (data, filter) => {
  const {
    allMdx: { nodes: postData },
  } = data;
  const filtered = postData.filter((element) =>
    element.frontmatter.path.includes(filter)
  );
  return filtered;
};

const PostTitle = () => {
  const isBrowser = typeof window !== "undefined";

  const url = isBrowser ? window.location.pathname : "";

  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            excerpt
            seoTitle
            title
            path
            postImage {
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `);
  const postData = filterData(data, url)[0];
  return (
    <PostTitleWrapper>
      <TextWrapper>
        <Title>{postData.frontmatter.title}</Title>
        <Excerpt>{postData.frontmatter.excerpt}</Excerpt>
      </TextWrapper>
      <PostImage
        alt=""
        fluid={postData.frontmatter.postImage.childImageSharp.fluid}
        objectFit="contain"
      />
    </PostTitleWrapper>
  );
};

export default PostTitle;
