import React from "react";
import styled from "styled-components";

import fileIco from "../../assets/images/icons/file.svg";
import downloadIco from "../../assets/images/icons/download.svg";
import Text from "../Text/Text";

const DownloadWrapper = styled.a`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.separator};
  border-radius: 5px;
  padding: 6px;
  width: fit-content;
  transition: 0.3s box-shadow, 0.3s background-color;
  :hover {
    background-color: rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
`;

const Ico = styled.img`
  margin: 0 12px;
  filter: invert(${({ theme }) => theme.filters.invert})
    grayscale(${({ theme }) => theme.filters.invert});
`;

const FileSize = styled.div`
  font-size: 10px;
`;

const Download = ({ url, fileSize, children, style }) => {
  return (
    <DownloadWrapper href={url} download style={style}>
      <Ico src={fileIco} alt="fileIco" />
      <Text>
        <div>{children}</div>
        <FileSize>{fileSize}</FileSize>
      </Text>
      <Ico src={downloadIco} alt="download" />
    </DownloadWrapper>
  );
};

export default Download;
