import React from "react";
import items from "./items";

const getRes = (fileName, name) => (
  <>
    <img
      src={
        require(`../../assets/images/icons/resourcecalc/${fileName}`).default
      }
      alt={name}
    />{" "}
    {name}
  </>
);

const getItem = (name) => items.find((element) => element.name === name);

const itemsRecipe = [
  // {
  //   value: "Absorbent Substrate",
  //   label: getRes("Absorbent_Substrate.png", "Absorbent Substrate"),
  //   recipe: [
  //     { item: getItem("Oil"), quantity: 8 },
  //     { item: getItem("Black Pearl"), quantity: 8 },
  //     { item: getItem("Sap"), quantity: 8 },
  //   ],
  // },
  // {
  //   value: "Cementing Paste (Hexagon)",
  //   label: getRes("Cementing_Paste.png", "Cementing Paste (Hexagon)"),
  //   recipe: [
  //     { item: getItem("Cementing Paste"), quantity: 1 },
      
  //   ],
  // },
  // {
  //   value: "Cementing Paste (Mortar)",
  //   label: getRes("Cementing_Paste.png", "Cementing Paste (Mortar)"),
  //   recipe: [
  //     { item: getItem("Stone"), quantity: 8 },
  //     { item: getItem("Keratin"), quantity: 4 },
  //   ],
  // },
  // {
  //   value: "Cementing Paste (Chemistry Bench)",
  //   label: getRes("Cementing_Paste.png", "Cementing Paste (Chemistry Bench)"),
  //   recipe: [
  //     { item: getItem("Stone"), quantity: 32 },
  //     { item: getItem("Keratin"), quantity: 16 },
  //   ],
  // },
  // {
  //   value: "Advanced Bullet",
  //   label: getRes("Advanced_Bullet.png", "Advanced Bullet"),
  //   recipe: [
  //     { item: getItem("Metal Ingot"), quantity: 1 },
  //     // Brakuje itemu!!!
  //   ],
  // },




  {
    value: "Advanced Bullet",
    label: getRes("Advanced_Bullet.png", "Advanced Bullet"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 0.333333 },
      { item: getItem("Gunpowder"), quantity: 1 },
    ],
  },  
  {
    value: "Advanced Rifle Bullet",
    label: getRes("Advanced_Rifle_Bullet.png", "Advanced Rifle Bullet"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 0.5 },
      { item: getItem("Gunpowder"), quantity: 4.5 },
    ],
  },  
  {
    value: "Advanced Sniper Bullet",
    label: getRes("Advanced_Sniper_Bullet.png", "Advanced Sniper Bullet"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 1 },
      { item: getItem("Gunpowder"), quantity: 6 },
    ],
  },  
  {
    value: "Air Conditioner",
    label: getRes("Air_Conditioner.png", "Air Conditioner"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 80 },
      { item: getItem("Electronics"), quantity: 15 },
      { item: getItem("Polymer"), quantity: 5 },
      { item: getItem("Crystal"), quantity: 15 },
    ],
  },  
  {
    value: "Ammo Box",
    label: getRes("Ammo_Box.png", "Ammo Box"),
    recipe: [
      { item: getItem("Wood"), quantity: 250 },
      { item: getItem("Fiber"), quantity: 500 },
      { item: getItem("Metal"), quantity: 100 },
    ],
  },  
  {
    value: "Auto Turret",
    label: getRes("Auto_Turret.png", "Auto Turret"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 140 },
      { item: getItem("Electronics"), quantity: 70 },
      { item: getItem("Polymer"), quantity: 20 },
      { item: getItem("Cementing Paste"), quantity: 50 },
    ],
  },  
  {
    value: "Bear Trap",
    label: getRes("Bear_Trap.png", "Bear Trap"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 3 },
      { item: getItem("Hide"), quantity: 6 },
      { item: getItem("Fiber"), quantity: 5 },
    ],
  },  
  {
    value: "Beer Barrel",
    label: getRes("Beer_Barrel.png", "Beer Barrel"),
    recipe: [
      { item: getItem("Wood"), quantity: 500 },
      { item: getItem("Metal Ingot"), quantity: 80 },
      { item: getItem("Cementing Paste"), quantity: 100 },
    ],
  },  
  {
    value: "Behemoth Gate",
    label: getRes("Behemoth_Gate.png", "Behemoth Gate"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 1500 },
      { item: getItem("Cementing Paste"), quantity: 350 },
    ],
  },  
  {
    value: "Behemoth Gateway",
    label: getRes("Behemoth_Gateway.png", "Behemoth Gateway"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 2100 },
      { item: getItem("Polymer"), quantity: 140 },
      { item: getItem("Cementing Paste"), quantity: 490 },
    ],
  },  
  {
    value: "Behemoth Tek Gate",
    label: getRes("Behemoth_Tek_Gate.png", "Behemoth Tek Gate"),
    recipe: [
      { item: getItem("Crystal"), quantity: 1500 },
      { item: getItem("Polymer"), quantity: 350 },
      { item: getItem("Element"), quantity: 20 },
    ],
  },  
  {
    value: "Behemoth Tek Gateway",
    label: getRes("Behemoth_Tek_Gateway.png", "Behemoth Tek Gateway"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 2200 },
      { item: getItem("Crystal"), quantity: 140 },
      { item: getItem("Polymer"), quantity: 500 },
      { item: getItem("Element"), quantity: 40 },
    ],
  },  
  {
    value: "Bola",
    label: getRes("Bola.png", "Bola"),
    recipe: [
      { item: getItem("Thatch"), quantity: 10 },
      { item: getItem("Stone"), quantity: 3 },
      { item: getItem("Fiber"), quantity: 15 },
      { item: getItem("Hide"), quantity: 3 },
    ],
  },  
  {
    value: "C4 Charge",
    label: getRes("C4_Charge.png", "C4 Charge"),
    recipe: [
      { item: getItem("Gunpowder"), quantity: 60 },
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Cementing Paste"), quantity: 5 },
      { item: getItem("Fiber"), quantity: 50 },
      { item: getItem("Hide"), quantity: 5 },
      { item: getItem("Polymer"), quantity: 5 },
      { item: getItem("Electronics"), quantity: 5 },
    ],
  },  
  {
    value: "C4 Remote Detonator",
    label: getRes("C4_Remote_Detonator.png", "C4 Remote Detonator"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 10 },
      { item: getItem("Electronics"), quantity: 50 },
      { item: getItem("Polymer"), quantity: 20 },
      { item: getItem("Cementing Paste"), quantity: 15 },
      { item: getItem("Crystal"), quantity: 10 },
    ],
  },  
  {
    value: "Cannon",
    label: getRes("Cannon.png", "Cannon"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 950 },
      { item: getItem("Fiber"), quantity: 200 },
      { item: getItem("Hide"), quantity: 150 },
      { item: getItem("Thatch"), quantity: 120 },
      { item: getItem("Cementing Paste"), quantity: 300 },
    ],
  },  
  {
    value: "Cannon Ball",
    label: getRes("Cannon_Ball.png", "Cannon Ball"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 80 },
      { item: getItem("Gunpowder"), quantity: 30 },
      { item: getItem("Cementing Paste"), quantity: 20 },
      { item: getItem("Obsidian"), quantity: 4 },
    ],
  },  
  {
    value: "Charcoal",
    label: getRes("Charcoal.png", "Charcoal"),
    recipe: [
      { item: getItem("Wood"), quantity: 1 },
    ],
  }, 
  {
    value: "Charge Battery",
    label: getRes("Charge_Battery.png", "Charge Battery"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 10 },
      { item: getItem("Gas Ball"), quantity: 20 },
      { item: getItem("Polymer"), quantity: 15 },
      { item: getItem("Crystal"), quantity: 4 },
    ],
  },  
  {
    value: "Chemistry Bench",
    label: getRes("Chemistry_Bench.png", "Chemistry Bench"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 250 },
      { item: getItem("Cementing Paste"), quantity: 250 },
      { item: getItem("Sparkpowder"), quantity: 100 },
      { item: getItem("Crystal"), quantity: 250 },
      { item: getItem("Polymer"), quantity: 250 },
      { item: getItem("Electronics"), quantity: 250 },
    ],
  },  
  {
    value: "Cloning Chamber",
    label: getRes("Cloning_Chamber.png", "Cloning Chamber"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5500 },
      { item: getItem("Polymer"), quantity: 800 },
      { item: getItem("Crystal"), quantity: 550 },
      { item: getItem("Black Pearl"), quantity: 200 },
      { item: getItem("Element"), quantity: 100 },
    ],
  },  
  {
    value: "Cluster Grenade",
    label: getRes("Cluster_Grenade.png", "Cluster Grenade"),
    recipe: [
      { item: getItem("Gunpowder"), quantity: 65 },
      { item: getItem("Crystal"), quantity: 25 },
      { item: getItem("Cementing Paste"), quantity: 20 },
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Polymer"), quantity: 15 },
      { item: getItem("Propellant"), quantity: 25 },
    ],
  },  
  {
    value: "Cooking Pot",
    label: getRes("Cooking_Pot.png", "Cooking Pot"),
    recipe: [
      { item: getItem("Stone"), quantity: 75 },
      { item: getItem("Thatch"), quantity: 15 },
      { item: getItem("Wood"), quantity: 10 },
      { item: getItem("Flint"), quantity: 5 },
    ],
  },  
  {
    value: "Crossbow",
    label: getRes("Crossbow.png", "Crossbow"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 7 },
      { item: getItem("Wood"), quantity: 10 },
      { item: getItem("Fiber"), quantity: 35 },
    ],
  },  
  {
    value: "Cryofridge",
    label: getRes("Cryofridge.png", "Cryofridge"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 225 },
      { item: getItem("Polymer"), quantity: 60 },
      { item: getItem("Electronics"), quantity: 15 },
      { item: getItem("Crystal"), quantity: 25 },
    ],
  },  
  {
    value: "Cryopod",
    label: getRes("Cryopod.png", "Cryopod"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 2 },
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Fiber"), quantity: 15 },
      { item: getItem("Hide"), quantity: 5 },
      { item: getItem("Oil"), quantity: 4 },
      { item: getItem("Polymer"), quantity: 8 },
    ],
  },  
  {
    value: "Egg Incubator",
    label: getRes("Egg_Incubator.png", "Egg Incubator"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 500 },
      { item: getItem("Polymer"), quantity: 350 },
      { item: getItem("Crystal"), quantity: 200 },
      { item: getItem("Cementing Paste"), quantity: 100 },
      { item: getItem("Electronics"), quantity: 250 },
    ],
  },  
  {
    value: "Electrical Generator",
    label: getRes("Electrical_Generator.png", "Electrical Generator"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 25 },
      { item: getItem("Electronics"), quantity: 10 },
    ],
  },  
  {
    value: "Electrical Outlet",
    label: getRes("Electrical_Outlet.png", "Electrical Outlet"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Wood"), quantity: 15 },
      { item: getItem("Electronics"), quantity: 3 },
    ],
  },  
  {
    value: "Electrical Cable Intersection",
    label: getRes("Electrical_Cable_Intersection.png", "Electrical Cable Intersection"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 2 },
    ],
  },  
  {
    value: "Element",
    label: getRes("Element.png", "Element"),
    recipe: [
      { item: getItem("Green Gem"), quantity: 20 },
      { item: getItem("Blue Gem"), quantity: 15 },
      { item: getItem("Red Gem"), quantity: 10 },
      { item: getItem("Gas Ball"), quantity: 50 },
      { item: getItem("Element Ore"), quantity: 20 },
    ],
  },  
  {
    value: "Element Dust",
    label: getRes("Element_Dust.png", "Element Dust"),
    recipe: [
      { item: getItem("Element"), quantity: 0.005 },
    ],
  },  
  {
    value: "Element Shard",
    label: getRes("Element_Shard.png", "Element Shard"),
    recipe: [
      { item: getItem("Element"), quantity: 0.01 },
    ],
  },  
  {
    value: "Enduro Stew",
    label: getRes("Enduro_Stew.png", "Enduro Stew"),
    recipe: [
      { item: getItem("Cooked Meat"), quantity: 9 },
      { item: getItem("Rockarrot"), quantity: 5 },
      { item: getItem("Savoroot"), quantity: 5 },
      { item: getItem("Mejoberry"), quantity: 10 },
      { item: getItem("Stimulant"), quantity: 2 },
    ],
  },  
  {
    value: "Energy Brew",
    label: getRes("Energy_Brew.png", "Energy Brew"),
    recipe: [
      { item: getItem("Azulberry"), quantity: 20 },
      { item: getItem("Stimulant"), quantity: 2 },
    ],
  },  
  {
    value: "Fabricator",
    label: getRes("Fabricator.png", "Fabricator"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Cementing Paste"), quantity: 20 },
      { item: getItem("Sparkpowder"), quantity: 50 },
      { item: getItem("Crystal"), quantity: 15 },
      { item: getItem("Oil"), quantity: 10 },
    ],
  },  
  {
    value: "Feeding Trough",
    label: getRes("Feeding_Trough.png", "Feeding Trough"),
    recipe: [
      { item: getItem("Wood"), quantity: 120 },
      { item: getItem("Thatch"), quantity: 60 },
      { item: getItem("Fiber"), quantity: 40 },
      { item: getItem("Metal"), quantity: 8 },
    ],
  },  
  {
    value: "Filled Fish Basket",
    label: getRes("Filled_Fish_Basket.png", "Filled Fish Basket"),
    recipe: [
      { item: getItem("Fungal Wood"), quantity: 60 },
      { item: getItem("Fiber"), quantity: 120 },
      { item: getItem("Thatch"), quantity: 40 },
    ],
  },  
  {
    value: "Fish Net",
    label: getRes("Fish_Net.png", "Fish Net"),
    recipe: [
      { item: getItem("Thatch"), quantity: 10 },
      { item: getItem("Stone"), quantity: 3 },
      { item: getItem("Fiber"), quantity: 15 },
      { item: getItem("Hide"), quantity: 3 },
    ],
  },  
  {
    value: "Flame Arrow",
    label: getRes("Flame_Arrow.png", "Flame Arrow"),
    recipe: [
      { item: getItem("Stone Arrow"), quantity: 1 },
      { item: getItem("Propellant"), quantity: 1 },
    ],
  },  
  {
    value: "Flamethrower",
    label: getRes("Flamethrower.png", "Flamethrower"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Polymer"), quantity: 75 },
      { item: getItem("Cementing Paste"), quantity: 50 },
      { item: getItem("Sulfur"), quantity: 10 },
      { item: getItem("Electronics"), quantity: 15 },
    ],
  },  
  {
    value: "Flamethrower Ammo",
    label: getRes("Flamethrower_Ammo.png", "Flamethrower Ammo"),
    recipe: [
      { item: getItem("Propellant"), quantity: 10 },
      { item: getItem("Gas"), quantity: 10 },
    ],
  },  
  {
    value: "Flexible Electrical Cable",
    label: getRes("Flexible_Electrical_Cable.png", "Flexible Electrical Cable"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 2 },
    ],
  },  
  {
    value: "Focal Chili",
    label: getRes("Focal_Chili.png", "Focal Chili"),
    recipe: [
      { item: getItem("Cooked Meat"), quantity: 9 },
      { item: getItem("Citronal"), quantity: 5 },
      { item: getItem("Amarberry"), quantity: 20 },
      { item: getItem("Azulberry"), quantity: 20 },
      { item: getItem("Tintoberry"), quantity: 20 },
      { item: getItem("Mejoberry"), quantity: 10 },
    ],
  }, 
  {
    value: "Fria Curry",
    label: getRes("Fria_Curry.png", "Fria Curry"),
    recipe: [
      { item: getItem("Longrass"), quantity: 5 },
      { item: getItem("Rockarrot"), quantity: 5 },
      { item: getItem("Azulberry"), quantity: 20 },
      { item: getItem("Mejoberry"), quantity: 10 },
      { item: getItem("Narcotic"), quantity: 2 },
    ],
  },   
  {
    value: "Gas Collector",
    label: getRes("Gas_Collector.png", "Gas Collector"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 75 },
      { item: getItem("Cementing Paste"), quantity: 60 },
      { item: getItem("Crystal"), quantity: 40 },
      { item: getItem("Red Gem"), quantity: 20 },
    ],
  },   
  {
    value: "Gas Mask",
    label: getRes("Gas_Mask.png", "Gas Mask"),
    recipe: [
      { item: getItem("Polymer"), quantity: 20 },
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Absorbent Substrate"), quantity: 2 },
    ],
  },   
  {
    value: "Gasoline",
    label: getRes("Gasoline.png", "Gasoline"),
    recipe: [
      { item: getItem("Hide"), quantity: 5 },
      { item: getItem("Oil"), quantity: 6 },
    ],
  },   
  {
    value: "Giant Metal Hatchframe",
    label: getRes("Giant_Metal_Hatchframe.png", "Giant Metal Hatchframe"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 240 },
      { item: getItem("Cementing Paste"), quantity: 64 },
    ],
  },   
  {
    value: "Giant Metal Trapdoor",
    label: getRes("Giant_Metal_Trapdoor.png", "Giant Metal Trapdoor"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 360 },
      { item: getItem("Cementing Paste"), quantity: 120 },
    ],
  },   
  {
    value: "GPS",
    label: getRes("GPS.png", "GPS"),
    recipe: [
      { item: getItem("Polymer"), quantity: 5 },
      { item: getItem("Electronics"), quantity: 20 },
    ],
  },   
  {
    value: "Grappling Hook",
    label: getRes("Grappling_Hook.png", "Grappling Hook"),
    recipe: [
      { item: getItem("Stone Arrow"), quantity: 3 },
      { item: getItem("Cementing Paste"), quantity: 1 },
      { item: getItem("Metal Ingot"), quantity: 9 },
      { item: getItem("Thatch"), quantity: 2 },
      { item: getItem("Fiber"), quantity: 35 },
    ],
  },   
  {
    value: "Grenade",
    label: getRes("Grenade.png", "Grenade"),
    recipe: [
      { item: getItem("Fiber"), quantity: 15 },
      { item: getItem("Stone"), quantity: 20 },
      { item: getItem("Gunpowder"), quantity: 30 },
      { item: getItem("Hide"), quantity: 5 },
      { item: getItem("Metal Ingot"), quantity: 2 },
      { item: getItem("Oil"), quantity: 4 },
    ],
  },   
  {
    value: "Gunpowder",
    label: getRes("Gunpowder.png", "Gunpowder (Chem Bench)"),
    recipe: [
      { item: getItem("Sparkpowder"), quantity: 0.666666666666 },
      { item: getItem("Charcoal"), quantity: 0.666666666666 },
    ],
  },   
  {
    value: "Hazard Suit Boots",
    label: getRes("Hazard_Suit_Boots.png", "Hazard Suit Boots"),
    recipe: [
      { item: getItem("Hide"), quantity: 20 },
      { item: getItem("Fiber"), quantity: 25 },
      { item: getItem("Blue Gem"), quantity: 5 },
      { item: getItem("Polymer"), quantity: 10 },
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Gas Ball"), quantity: 15 },
    ],
  },   
  {
    value: "Hazard Suit Gloves",
    label: getRes("Hazard_Suit_Gloves.png", "Hazard Suit Gloves"),
    recipe: [
      { item: getItem("Hide"), quantity: 15 },
      { item: getItem("Fiber"), quantity: 35 },
      { item: getItem("Blue Gem"), quantity: 8 },
      { item: getItem("Polymer"), quantity: 2 },
      { item: getItem("Crystal"), quantity: 16 },
      { item: getItem("Gas Ball"), quantity: 15 },
    ],
  },   
  {
    value: "Hazard Suit Hat",
    label: getRes("Hazard_Suit_Hat.png", "Hazard Suit Hat"),
    recipe: [
      { item: getItem("Hide"), quantity: 30 },
      { item: getItem("Fiber"), quantity: 45 },
      { item: getItem("Blue Gem"), quantity: 25 },
      { item: getItem("Polymer"), quantity: 20 },
      { item: getItem("Crystal"), quantity: 50 },
      { item: getItem("Gas Ball"), quantity: 20 },
    ],
  },   
  {
    value: "Hazard Suit Pants",
    label: getRes("Hazard_Suit_Pants.png", "Hazard Suit Pants"),
    recipe: [
      { item: getItem("Hide"), quantity: 45 },
      { item: getItem("Fiber"), quantity: 65 },
      { item: getItem("Blue Gem"), quantity: 20 },
      { item: getItem("Polymer"), quantity: 15 },
      { item: getItem("Crystal"), quantity: 40 },
      { item: getItem("Gas Ball"), quantity: 30 },
    ],
  },   
  {
    value: "Hazard Suit Shirt",
    label: getRes("Hazard_Suit_Shirt.png", "Hazard Suit Shirt"),
    recipe: [
      { item: getItem("Hide"), quantity: 45 },
      { item: getItem("Fiber"), quantity: 65 },
      { item: getItem("Blue Gem"), quantity: 25 },
      { item: getItem("Polymer"), quantity: 25 },
      { item: getItem("Crystal"), quantity: 50 },
      { item: getItem("Gas Ball"), quantity: 45 },
    ],
  },   
  {
    value: "Heavy Auto Turret",
    label: getRes("Heavy_Auto_Turret.png", "Heavy Auto Turret"),
    recipe: [
      { item: getItem("Auto Turret"), quantity: 1 },
      { item: getItem("Cementing Paste"), quantity: 150 },
      { item: getItem("Electronics"), quantity: 200 },
      { item: getItem("Metal Ingot"), quantity: 400 },
      { item: getItem("Polymer"), quantity: 50 },
    ],
  },   
  {
    value: "Homing Underwater Mine",
    label: getRes("Homing_Underwater_Mine.png", "Homing Underwater Mine"),
    recipe: [
      { item: getItem("Polymer"), quantity: 45 },
      { item: getItem("Metal Ingot"), quantity: 30 },
      { item: getItem("Cementing Paste"), quantity: 20 },
      { item: getItem("Oil"), quantity: 15 },
      { item: getItem("Gunpowder"), quantity: 100 },
      { item: getItem("Crystal"), quantity: 30 },
      { item: getItem("Electronics"), quantity: 150 },
    ],
  },   
  {
    value: "Industrial Cooker",
    label: getRes("Industrial_Cooker.png", "Industrial Cooker"),
    recipe: [
      { item: getItem("Polymer"), quantity: 300 },
      { item: getItem("Metal Ingot"), quantity: 1800 },
      { item: getItem("Cementing Paste"), quantity: 450 },
      { item: getItem("Oil"), quantity: 300 },
    ],
  },   
  {
    value: "Industrial Forge",
    label: getRes("Industrial_Forge.png", "Industrial Forge"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 2500 },
      { item: getItem("Crystal"), quantity: 250 },
      { item: getItem("Cementing Paste"), quantity: 600 },
      { item: getItem("Oil"), quantity: 400 },
      { item: getItem("Polymer"), quantity: 400 },
    ],
  },   
  {
    value: "Industrial Grill",
    label: getRes("Industrial_Grill.png", "Industrial Grill"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 200 },
      { item: getItem("Cementing Paste"), quantity: 40 },
      { item: getItem("Oil"), quantity: 40 },
      { item: getItem("Crystal"), quantity: 30 },
    ],
  },   
  {
    value: "Industrial Grinder",
    label: getRes("Industrial_Grinder.png", "Industrial Grinder"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 3200 },
      { item: getItem("Crystal"), quantity: 2000 },
      { item: getItem("Cementing Paste"), quantity: 800 },
      { item: getItem("Oil"), quantity: 600 },
      { item: getItem("Polymer"), quantity: 600 },
    ],
  },   
  {
    value: "Jar of Pitch",
    label: getRes("Jar_of_Pitch.png", "Jar of Pitch"),
    recipe: [
      { item: getItem("Clay"), quantity: 60 },
      { item: getItem("Oil"), quantity: 180 },
      { item: getItem("Hide"), quantity: 20 },
      { item: getItem("Fiber"), quantity: 85 },
    ],
  },   
  {
    value: "Large Bear Trap",
    label: getRes("Large_Bear_Trap.png", "Large Bear Trap"),
    recipe: [
      { item: getItem("Fiber"), quantity: 10 },
      { item: getItem("Metal Ingot"), quantity: 6 },
      { item: getItem("Hide"), quantity: 15 },
    ],
  },   
  {
    value: "Large Metal Wall",
    label: getRes("Large_Metal_Wall.png", "Large Metal Wall"),
    recipe: [
      { item: getItem("Cementing Paste"), quantity: 28 },
      { item: getItem("Metal Ingot"), quantity: 100 },
    ],
  },   
  {
    value: "Large Crop Plot",
    label: getRes("Large_Crop_Plot.png", "Large Crop Plot"),
    recipe: [
      { item: getItem("Wood"), quantity: 80 },
      { item: getItem("Thatch"), quantity: 40 },
      { item: getItem("Fiber"), quantity: 60 },
      { item: getItem("Stone"), quantity: 100 },
    ],
  },   
  {
    value: "Large Storage Box",
    label: getRes("Large_Storage_Box.png", "Large Storage Box"),
    recipe: [
      { item: getItem("Wood"), quantity: 50 },
      { item: getItem("Thatch"), quantity: 35 },
      { item: getItem("Fiber"), quantity: 25 },
    ],
  },   
  {
    value: "Large Tek Wall",
    label: getRes("Large_Tek_Wall.png", "Large Tek Wall"),
    recipe: [
      { item: getItem("Crystal"), quantity: 60 },
      { item: getItem("Element"), quantity: 4 },
      { item: getItem("Metal Ingot"), quantity: 140 },
      { item: getItem("Polymer"), quantity: 80 },
    ],
  },   
  {
    value: "Lazarus Chowder",
    label: getRes("Lazarus_Chowder.png", "Lazarus Chowder"),
    recipe: [
      { item: getItem("Cooked Meat"), quantity: 9 },
      { item: getItem("Savoroot"), quantity: 5 },
      { item: getItem("Longrass"), quantity: 5 },
      { item: getItem("Mejoberry"), quantity: 10 },
      { item: getItem("Narcotic"), quantity: 2 },
    ],
  },   
  {
    value: "Lesser Antidote",
    label: getRes("Lesser_Antidote.png", "Lesser Antidote"),
    recipe: [
      { item: getItem("Rare Flowers"), quantity: 10 },
      { item: getItem("Rare Mushrooms"), quantity: 10 },
      { item: getItem("Leech Blood"), quantity: 3 },
      { item: getItem("Narcotic"), quantity: 1 },
    ],
  },   
  {
    value: "Loadout Mannequin",
    label: getRes("Loadout_Mannequin.png", "Loadout Mannequin"),
    recipe: [
      { item: getItem("Thatch"), quantity: 500 },
      { item: getItem("Wood"), quantity: 200 },
      { item: getItem("Fiber"), quantity: 500 },
      { item: getItem("Hide"), quantity: 10 },
    ],
  },
  {
    value: "MDSM",
    label: getRes("MDSM.png", "MDSM"),
    recipe: [
      { item: getItem("Black Pearl"), quantity: 30 },
      { item: getItem("Crystal"), quantity: 280 },
      { item: getItem("Element"), quantity: 30 },
      { item: getItem("Metal Ingot"), quantity: 400 },
      { item: getItem("Polymer"), quantity: 180 },
    ],
  },   
  {
    value: "Medical Brew",
    label: getRes("Medical_Brew.png", "Medical Brew"),
    recipe: [
      { item: getItem("Tintoberry"), quantity: 20 },
      { item: getItem("Narcotic"), quantity: 2 },
    ],
  },   
  {
    value: "Metal Billboard",
    label: getRes("Metal_Billboard.png", "Metal Billboard"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Cementing Paste"), quantity: 10 },
    ],
  },   
  {
    value: "Metal Arrow",
    label: getRes("Metal_Arrow.png", "Metal Arrow"),
    recipe: [
      { item: getItem("Thatch"), quantity: 3 },
      { item: getItem("Fiber"), quantity: 3 },
      { item: getItem("Metal Ingot"), quantity: 3 },
      { item: getItem("Cementing Paste"), quantity: 1 },
      { item: getItem("Polymer"), quantity: 1 },
    ],
  },   
  {
    value: "Metal Catwalk",
    label: getRes("Metal_Catwalk.png", "Metal Catwalk"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 15 },
    ],
  },   
  {
    value: "Metal Ceiling",
    label: getRes("Metal_Ceiling.png", "Metal Ceiling"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Cementing Paste"), quantity: 10 },
    ],
  },   
  {
    value: "Metal Cliff Platform",
    label: getRes("Metal_Cliff_Platform.png", "Metal Cliff Platform"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 1200 },
      { item: getItem("Cementing Paste"), quantity: 800 },
      { item: getItem("Blue Gem"), quantity: 200 },
    ],
  },   
  {
    value: "Metal Dinosaur Gate",
    label: getRes("Metal_Dinosaur_Gate.png", "Metal Dinosaur Gate"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Cementing Paste"), quantity: 10 },
    ],
  },   
  {
    value: "Metal Dinosaur Gateway",
    label: getRes("Metal_Dinosaur_Gateway.png", "Metal Dinosaur Gateway"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 170 },
      { item: getItem("Cementing Paste"), quantity: 50 },
    ],
  },   
  {
    value: "Metal Door",
    label: getRes("Metal_Door.png", "Metal Door"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 10 },
      { item: getItem("Cementing Paste"), quantity: 4 },
    ],
  },   
  {
    value: "Metal Doorframe",
    label: getRes("Metal_Doorframe.png", "Metal Doorframe"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 20 },
      { item: getItem("Cementing Paste"), quantity: 6 },
    ],
  },   
  {
    value: "Metal Double Door",
    label: getRes("Metal_Double_Door.png", "Metal Double Door"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 20 },
      { item: getItem("Cementing Paste"), quantity: 8 },
    ],
  },   
  {
    value: "Metal Double Doorframe",
    label: getRes("Metal_Double_Doorframe.png", "Metal Double Doorframe"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 20 },
      { item: getItem("Cementing Paste"), quantity: 6 },
    ],
  },   
  {
    value: "Metal Fence Foundation",
    label: getRes("Metal_Fence_Foundation.png", "Metal Fence Foundation"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Cementing Paste"), quantity: 2 },
    ],
  },   
  {
    value: "Metal Fence Support",
    label: getRes("Metal_Fence_Support.png", "Metal Fence Support"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Cementing Paste"), quantity: 2 },
    ],
  },   
  {
    value: "Metal Foundation",
    label: getRes("Metal_Foundation.png", "Metal Foundation"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 50 },
      { item: getItem("Cementing Paste"), quantity: 15 },
    ],
  },   
  {
    value: "Metal Hatchet",
    label: getRes("Metal_Hatchet.png", "Metal Hatchet"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 8 },
      { item: getItem("Wood"), quantity: 1 },
      { item: getItem("Hide"), quantity: 10 },
    ],
  },   
  {
    value: "Metal Hatchframe",
    label: getRes("Metal_Hatchframe.png", "Metal Hatchframe"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 30 },
      { item: getItem("Cementing Paste"), quantity: 8 },
    ],
  },   
  {
    value: "Metal Ladder",
    label: getRes("Metal_Ladder.png", "Metal Ladder"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 7 },
    ],
  },   
  {
    value: "Metal Ocean Platform",
    label: getRes("Metal_Ocean_Platform.png", "Metal Ocean Platform"),
    recipe: [
      { item: getItem("Wood"), quantity: 300 },
      { item: getItem("Metal Ingot"), quantity: 1500 },
      { item: getItem("Polymer"), quantity: 25 },
      { item: getItem("Cementing Paste"), quantity: 375 },
    ],
  },   
  {
    value: "Metal Pillar",
    label: getRes("Metal_Pillar.png", "Metal Pillar"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 25 },
      { item: getItem("Cementing Paste"), quantity: 7 },
    ],
  },   
  {
    value: "Metal Railing",
    label: getRes("Metal_Railing.png", "Metal Railing"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 12 },
      { item: getItem("Cementing Paste"), quantity: 3 },
    ],
  },   
  {
    value: "Metal Ramp",
    label: getRes("Metal_Ramp.png", "Metal Ramp"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Cementing Paste"), quantity: 10 },
    ],
  },   
  {
    value: "Metal Sign",
    label: getRes("Metal_Sign.png", "Metal Sign"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Cementing Paste"), quantity: 3 },
    ],
  },   
  {
    value: "Metal Spike Wall",
    label: getRes("Metal_Spike_Wall.png", "Metal Spike Wall"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 25 },
      { item: getItem("Hide"), quantity: 20 },
      { item: getItem("Fiber"), quantity: 30 },
    ],
  },   
  {
    value: "Metal Stairs",
    label: getRes("Metal_Stairs.png", "Metal Stairs"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Cementing Paste"), quantity: 10 },
    ],
  },   
  {
    value: "Metal Trapdoor",
    label: getRes("Metal_Trapdoor.png", "Metal Trapdoor"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 10 },
      { item: getItem("Cementing Paste"), quantity: 4 },
    ],
  },   
  {
    value: "Metal Tree Platform",
    label: getRes("Metal_Tree_Platform.png", "Metal Tree Platform"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 1800 },
      { item: getItem("Cementing Paste"), quantity: 1400 },
      { item: getItem("Fiber"), quantity: 600 },
    ],
  },   
  {
    value: "Metal Triangle Ceiling",
    label: getRes("Metal_Triangle_Ceiling.png", "Metal Triangle Ceiling"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 18 },
      { item: getItem("Cementing Paste"), quantity: 5 },
    ],
  },   
  {
    value: "Metal Triangle Foundation",
    label: getRes("Metal_Triangle_Foundation.png", "Metal Triangle Foundation"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 25 },
      { item: getItem("Cementing Paste"), quantity: 8 },
    ],
  },   
  {
    value: "Metal Wall",
    label: getRes("Metal_Wall.png", "Metal Wall"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 25 },
      { item: getItem("Cementing Paste"), quantity: 7 },
    ],
  },   
  {
    value: "Metal Wall Sign",
    label: getRes("Metal_Wall_Sign.png", "Metal Wall Sign"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Cementing Paste"), quantity: 3 },
    ],
  },   
  {
    value: "Metal Water Reservoir",
    label: getRes("Metal_Water_Reservoir.png", "Metal Water Reservoir"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 75 },
      { item: getItem("Cementing Paste"), quantity: 25 },
    ],
  },   
  {
    value: "Metal Windowframe",
    label: getRes("Metal_Windowframe.png", "Metal Windowframe"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 20 },
      { item: getItem("Cementing Paste"), quantity: 6 },
    ],
  },   
  {
    value: "Minigun",
    label: getRes("Minigun.png", "Minigun"),
    recipe: [
      { item: getItem("Polymer"), quantity: 175 },
      { item: getItem("Metal Ingot"), quantity: 500 },
      { item: getItem("Cementing Paste"), quantity: 100 },
      { item: getItem("Electronics"), quantity: 10 },
    ],
  },   
  {
    value: "Minigun Turret",
    label: getRes("Minigun_Turret.png", "Minigun Turret"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 500 },
      { item: getItem("Polymer"), quantity: 150 },
      { item: getItem("Cementing Paste"), quantity: 125 },
      { item: getItem("Hide"), quantity: 100 },
    ],
  },   
  {
    value: "Mining Drill",
    label: getRes("Mining_Drill.png", "Mining Drill"),
    recipe: [
      { item: getItem("Polymer"), quantity: 132 },
      { item: getItem("Metal Ingot"), quantity: 482 },
      { item: getItem("Crystal"), quantity: 180 },
      { item: getItem("Cementing Paste"), quantity: 120 },
      { item: getItem("Black Pearl"), quantity: 36 },
    ],
  },   
  {
    value: "Mortar And Pestle",
    label: getRes("Mortar_And_Pestle.png", "Mortar And Pestle"),
    recipe: [
      { item: getItem("Stone"), quantity: 65 },
      { item: getItem("Hide"), quantity: 15 },
    ],
  },
  {
    value: "Mosasaur Tek Saddle",
    label: getRes("Mosasaur_Tek_Saddle.png", "Mosasaur Tek Saddle"),
    recipe: [
      { item: getItem("Polymer"), quantity: 500 },
      { item: getItem("Metal Ingot"), quantity: 2500 },
      { item: getItem("Crystal"), quantity: 370 },
      { item: getItem("Element"), quantity: 55 },
      { item: getItem("Black Pearl"), quantity: 140 },
    ],
  },

  {
    value: "Motorboat",
    label: getRes("Motorboat.png", "Motorboat"),
    recipe: [
      { item: getItem("Wood"), quantity: 250 },
      { item: getItem("Metal Ingot"), quantity: 1200 },
      { item: getItem("Hide"), quantity: 200 },
      { item: getItem("Fiber"), quantity: 180 },
      { item: getItem("Obsidian"), quantity: 150 },
      { item: getItem("Polymer"), quantity: 400 },
      { item: getItem("Black Pearl"), quantity: 60 },
    ],
  },  
  {
    value: "Mutagen",
    label: getRes("Mutagen.png", "Mutagen"),
    recipe: [
      { item: getItem("Mutagel"), quantity: 133.333333 },
    ],
  },  
  {
    value: "Narcotic",
    label: getRes("Narcotic.png", "Narcotic"),
    recipe: [
      { item: getItem("Narcoberry"), quantity: 3.3333333 },
      { item: getItem("Spoiled Meat"), quantity: 0.666666 },
    ],
  },  
  {
    value: "Net Projectile",
    label: getRes("Net_Projectile.png", "Net Projectile"),
    recipe: [
      { item: getItem("Fiber"), quantity: 180 },
      { item: getItem("Metal Ingot"), quantity: 12 },
      { item: getItem("Polymer"), quantity: 4 },
    ],
  },  
  {
    value: "Oil Pump",
    label: getRes("Oil_Pump.png", "Oil Pump"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 75 },
      { item: getItem("Electronics"), quantity: 25 },
      { item: getItem("Crystal"), quantity: 25 },
    ],
  },  
  {
    value: "Painting Canvas",
    label: getRes("Painting_Canvas.png", "Painting Canvas"),
    recipe: [
      { item: getItem("Fiber"), quantity: 4 },
      { item: getItem("Wood"), quantity: 2 },
      { item: getItem("Hide"), quantity: 1 },
    ],
  },  
  {
    value: "Parachute",
    label: getRes("Parachute.png", "Parachute"),
    recipe: [
      { item: getItem("Thatch"), quantity: 40 },
      { item: getItem("Fiber"), quantity: 20 },
      { item: getItem("Hide"), quantity: 10 },
    ],
  },  
  {
    value: "Poison Grenade",
    label: getRes("Poison_Grenade.png", "Poison Grenade"),
    recipe: [
      { item: getItem("Narcotic"), quantity: 10 },
      { item: getItem("Charcoal"), quantity: 14 },
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Sparkpowder"), quantity: 18 },
      { item: getItem("Gunpowder"), quantity: 12 },
      { item: getItem("Crystal"), quantity: 5 },
      { item: getItem("Fiber"), quantity: 20 },
      { item: getItem("Flint"), quantity: 5 },
    ],
  },  
  {
    value: "Polymer",
    label: getRes("Polymer.png", "Polymer"),
    recipe: [
      { item: getItem("Obsidian"), quantity: 2 },
      { item: getItem("Cementing Paste"), quantity: 2 },
    ],
  },  
  {
    value: "Preserving Bin",
    label: getRes("Preserving_Bin.png", "Preserving Bin"),
    recipe: [
      { item: getItem("Stone"), quantity: 30 },
      { item: getItem("Wood"), quantity: 30 },
      { item: getItem("Thatch"), quantity: 10 },
      { item: getItem("Fiber"), quantity: 20 },
    ],
  },  
  {
    value: "Pressure Plate",
    label: getRes("Pressure_Plate.png", "Pressure Plate"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 32 },
      { item: getItem("Cementing Paste"), quantity: 5 },
      { item: getItem("Wood"), quantity: 130 },
      { item: getItem("Fiber"), quantity: 68 },
      { item: getItem("Thatch"), quantity: 170 },
    ],
  },  
  {
    value: "Propellant",
    label: getRes("Propellant.png", "Propellant"),
    recipe: [
      { item: getItem("Sulfur"), quantity: 0.666666 },
      { item: getItem("Cactus Sap"), quantity: 0.666666 },
      { item: getItem("Oil"), quantity: 0.666666 },
    ],
  },  
  {
    value: "Refining Forge",
    label: getRes("Refining_Forge.png", "Refining Forge"),
    recipe: [
      { item: getItem("Stone"), quantity: 125 },
      { item: getItem("Flint"), quantity: 5 },
      { item: getItem("Hide"), quantity: 65 },
      { item: getItem("Wood"), quantity: 20 },
      { item: getItem("Fiber"), quantity: 40 },
    ],
  },  
  {
    value: "Refrigerator",
    label: getRes("Refrigerator.png", "Refrigerator"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 120 },
      { item: getItem("Polymer"), quantity: 15 },
      { item: getItem("Crystal"), quantity: 25 },
      { item: getItem("Electronics"), quantity: 10 },
    ],
  },  
  {
    value: "Rex Tek Saddle",
    label: getRes("Rex_Tek_Saddle.png", "Rex Tek Saddle"),
    recipe: [
      { item: getItem("Polymer"), quantity: 350 },
      { item: getItem("Metal Ingot"), quantity: 1800 },
      { item: getItem("Crystal"), quantity: 250 },
      { item: getItem("Element"), quantity: 40 },
      { item: getItem("Black Pearl"), quantity: 100 },
    ],
  },  
  {
    value: "Riot Shield",
    label: getRes("Riot_Shield.png", "Riot Shield"),
    recipe: [
      { item: getItem("Polymer"), quantity: 120 },
      { item: getItem("Crystal"), quantity: 200 },
      { item: getItem("Silica Pearls"), quantity: 120 },
      { item: getItem("Metal Ingot"), quantity: 40 },
    ],
  },  
  {
    value: "Rock Drake Tek Saddle",
    label: getRes("Rock_Drake_Tek_Saddle.png", "Rock Drake Tek Saddle"),
    recipe: [
      { item: getItem("Polymer"), quantity: 455 },
      { item: getItem("Metal Ingot"), quantity: 2350 },
      { item: getItem("Crystal"), quantity: 325 },
      { item: getItem("Element"), quantity: 75 },
      { item: getItem("Black Pearl"), quantity: 185 },
    ],
  },  
  {
    value: "Rocket Homing Missile",
    label: getRes("Rocket_Homing_Missile.png", "Rocket Homing Missile"),
    recipe: [
      { item: getItem("Gunpowder"), quantity: 50 },
      { item: getItem("Crystal"), quantity: 20 },
      { item: getItem("Cementing Paste"), quantity: 40 },
      { item: getItem("Polymer"), quantity: 15 },
      { item: getItem("Metal Ingot"), quantity: 20 },
      { item: getItem("Electronics"), quantity: 5 },
    ],
  },  
  {
    value: "Rocket Launcher",
    label: getRes("Rocket_Launcher.png", "Rocket Launcher"),
    recipe: [
      { item: getItem("Polymer"), quantity: 80 },
      { item: getItem("Metal Ingot"), quantity: 50 },
      { item: getItem("Cementing Paste"), quantity: 60 },
    ],
  },  
  {
    value: "Rocket Propelled Grenade",
    label: getRes("Rocket_Propelled_Grenade.png", "Rocket Propelled Grenade"),
    recipe: [
      { item: getItem("Gunpowder"), quantity: 40 },
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Cementing Paste"), quantity: 20 },
      { item: getItem("Polymer"), quantity: 10 },
      { item: getItem("Metal Ingot"), quantity: 12 },
    ],
  },  
  {
    value: "Rocket Turret",
    label: getRes("Rocket_Turret.png", "Rocket Turret"),
    recipe: [
      { item: getItem("Polymer"), quantity: 400 },
      { item: getItem("Metal Ingot"), quantity: 1100 },
      { item: getItem("Cementing Paste"), quantity: 300 },
      { item: getItem("Electronics"), quantity: 500 },
    ],
  },  
  {
    value: "SCUBA Flippers",
    label: getRes("SCUBA_Flippers.png", "SCUBA Flippers"),
    recipe: [
      { item: getItem("Hide"), quantity: 12 },
      { item: getItem("Fiber"), quantity: 5 },
      { item: getItem("Keratin"), quantity: 12 },
      { item: getItem("Cementing Paste"), quantity: 6 },
      { item: getItem("Silica Pearls"), quantity: 4 },
    ],
  },  
  {
    value: "SCUBA Leggings",
    label: getRes("SCUBA_Leggings.png", "SCUBA Leggings"),
    recipe: [
      { item: getItem("Hide"), quantity: 40 },
      { item: getItem("Fiber"), quantity: 4 },
      { item: getItem("Metal Ingot"), quantity: 2 },
      { item: getItem("Polymer"), quantity: 40 },
    ],
  },  
  {
    value: "SCUBA Mask",
    label: getRes("SCUBA_Mask.png", "SCUBA Mask"),
    recipe: [
      { item: getItem("Hide"), quantity: 15 },
      { item: getItem("Fiber"), quantity: 6 },
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Silica Pearls"), quantity: 3 },
    ],
  },  
  {
    value: "SCUBA Tank",
    label: getRes("SCUBA_Tank.png", "SCUBA Tank"),
    recipe: [
      { item: getItem("Hide"), quantity: 20 },
      { item: getItem("Fiber"), quantity: 8 },
      { item: getItem("Metal Ingot"), quantity: 14 },
      { item: getItem("Polymer"), quantity: 3 },
    ],
  },  
  {
    value: "Shadow Steak Saute",
    label: getRes("Shadow_Steak_Saute.png", "Shadow Steak Saute"),
    recipe: [
      { item: getItem("Cooked Prime Meat"), quantity: 3 },
      { item: getItem("Mejoberry"), quantity: 20 },
      { item: getItem("Narcotic"), quantity: 8 },
      { item: getItem("Rare Mushrooms"), quantity: 2 },
      { item: getItem("Savoroot"), quantity: 1 },
      { item: getItem("Rockarrot"), quantity: 1 },
    ],
  },  
  {
    value: "Shocking Tranquilizer Dart",
    label: getRes("Shocking_Tranquilizer_Dart.png", "Shocking Tranquilizer Dart"),
    recipe: [
      { item: getItem("Bio Toxin"), quantity: 3 },
      { item: getItem("Metal Ingot"), quantity: 2 },
      { item: getItem("Tranquilizer Dart"), quantity: 1 },
    ],
  },  
  {
    value: "Silencer Attachment",
    label: getRes("Silencer_Attachment.png", "Silencer Attachment"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 50 },
      { item: getItem("Oil"), quantity: 5 },
      { item: getItem("Keratin"), quantity: 20 },
      { item: getItem("Hide"), quantity: 10 },
    ],
  },  
  {
    value: "Simple Bed",
    label: getRes("Simple_Bed.png", "Simple Bed"),
    recipe: [
      { item: getItem("Wood"), quantity: 15 },
      { item: getItem("Thatch"), quantity: 80 },
      { item: getItem("Fiber"), quantity: 30 },
      { item: getItem("Hide"), quantity: 40 },
    ],
  },  
  {
    value: "Simple Bullet",
    label: getRes("Simple_Bullet.png", "Simple Bullet"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 0.5 },
      { item: getItem("Gunpowder"), quantity: 3 },
    ],
  },  
  {
    value: "Simple Rifle Ammo",
    label: getRes("Simple_Rifle_Ammo.png", "Simple Rifle Ammo"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 1 },
      { item: getItem("Gunpowder"), quantity: 6 },
    ],
  },  
  {
    value: "Simple Shotgun Ammo",
    label: getRes("Simple_Shotgun_Ammo.png", "Simple Shotgun Ammo"),
    recipe: [
      { item: getItem("Simple Bullet"), quantity: 3 },
      { item: getItem("Metal Ingot"), quantity: 1 },
      { item: getItem("Gunpowder"), quantity: 3 },
    ],
  },  
  {
    value: "Smithy",
    label: getRes("Smithy.png", "Smithy"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Stone"), quantity: 50 },
      { item: getItem("Wood"), quantity: 30 },
      { item: getItem("Hide"), quantity: 20 },
    ],
  },  
  {
    value: "Sparkpowder",
    label: getRes("Sparkpowder.png", "Sparkpowder"),
    recipe: [
      { item: getItem("Flint"), quantity: 0.666666666666 },
      { item: getItem("Stone"), quantity: 0.333333333333 },
    ],
  },  
  {
    value: "Spyglass",
    label: getRes("Spyglass.png", "Spyglass"),
    recipe: [
      { item: getItem("Wood"), quantity: 5 },
      { item: getItem("Hide"), quantity: 10 },
      { item: getItem("Fiber"), quantity: 10 },
      { item: getItem("Crystal"), quantity: 2 },
    ],
  },  
  {
    value: "Standing Torch",
    label: getRes("Standing_Torch.png", "Standing Torch"),
    recipe: [
      { item: getItem("Thatch"), quantity: 8 },
      { item: getItem("Flint"), quantity: 1 },
      { item: getItem("Stone"), quantity: 1 },
      { item: getItem("Wood"), quantity: 3 },
    ],
  },  
  {
    value: "Stimulant",
    label: getRes("Stimulant.png", "Stimulant"),
    recipe: [
      { item: getItem("Stimberry"), quantity: 3.33333 },
      { item: getItem("Sparkpowder"), quantity: 1.33333 },
    ],
  },  
  {
    value: "Stone Arrow",
    label: getRes("Stone_Arrow.png", "Stone Arrow"),
    recipe: [
      { item: getItem("Thatch"), quantity: 2 },
      { item: getItem("Fiber"), quantity: 2 },
      { item: getItem("Flint"), quantity: 1 },
    ],
  },  
  {
    value: "Stone Cliff Platform",
    label: getRes("Stone_Cliff_Platform.png", "Stone Cliff Platform"),
    recipe: [
      { item: getItem("Stone"), quantity: 1200 },
      { item: getItem("Fungal Wood"), quantity: 800 },
      { item: getItem("Fiber"), quantity: 600 },
      { item: getItem("Cementing Paste"), quantity: 250 },
    ],
  },  
  {
    value: "Storage Box",
    label: getRes("Storage_Box.png", "Storage Box"),
    recipe: [
      { item: getItem("Wood"), quantity: 25 },
      { item: getItem("Thatch"), quantity: 20 },
      { item: getItem("Fiber"), quantity: 10 },
    ],
  },  
  {
    value: "Sweet Vegetable Cake",
    label: getRes("Sweet_Vegetable_Cake.png", "Sweet Vegetable Cake"),
    recipe: [
      { item: getItem("Giant Bee Honey"), quantity: 2 },
      { item: getItem("Sap"), quantity: 4 },
      { item: getItem("Rockarrot"), quantity: 2 },
      { item: getItem("Longrass"), quantity: 2 },
      { item: getItem("Savoroot"), quantity: 2 },
      { item: getItem("Stimulant"), quantity: 4 },
      { item: getItem("Fiber"), quantity: 25 },
    ],
  },  
  {
    value: "Tapejara Tek Saddle",
    label: getRes("Tapejara_Tek_Saddle.png", "Tapejara Tek Saddle"),
    recipe: [
      { item: getItem("Polymer"), quantity: 500 },
      { item: getItem("Metal Ingot"), quantity: 2500 },
      { item: getItem("Crystal"), quantity: 370 },
      { item: getItem("Element"), quantity: 55 },
      { item: getItem("Black Pearl"), quantity: 140 },
    ],
  },  
  {
    value: "Tek Boots",
    label: getRes("Tek_Boots.png", "Tek Boots"),
    recipe: [
      { item: getItem("Polymer"), quantity: 120 },
      { item: getItem("Metal Ingot"), quantity: 450 },
      { item: getItem("Crystal"), quantity: 120 },
      { item: getItem("Element"), quantity: 20 },
      { item: getItem("Black Pearl"), quantity: 55 },
    ],
  },  
  {
    value: "Tek Bow",
    label: getRes("Tek_Bow.png", "Tek Bow"),
    recipe: [
      { item: getItem("Polymer"), quantity: 225 },
      { item: getItem("Metal Ingot"), quantity: 200 },
      { item: getItem("Cementing Paste"), quantity: 75 },
      { item: getItem("Element"), quantity: 60 },
      { item: getItem("Crystal"), quantity: 100 },
    ],
  },  
  {
    value: "Tek Bridge",
    label: getRes("Tek_Bridge.png", "Tek Bridge"),
    recipe: [
      { item: getItem("Crystal"), quantity: 150 },
      { item: getItem("Element"), quantity: 2 },
      { item: getItem("Metal Ingot"), quantity: 220 },
      { item: getItem("Polymer"), quantity: 100 },
    ],
  },  
  {
    value: "Tek Canteen",
    label: getRes("Tek_Canteen.png", "Tek Canteen"),
    recipe: [
      { item: getItem("Polymer"), quantity: 10 },
      { item: getItem("Element"), quantity: 5 },
      { item: getItem("Cementing Paste"), quantity: 6 },
      { item: getItem("Metal Ingot"), quantity: 2 },
    ],
  },  
  {
    value: "Tek Catwalk",
    label: getRes("Tek_Catwalk.png", "Tek Catwalk"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 18 },
      { item: getItem("Polymer"), quantity: 10 },
      { item: getItem("Crystal"), quantity: 5 },
      { item: getItem("Element"), quantity: 0.25 },
    ],
  },  
  {
    value: "Tek Ceiling",
    label: getRes("Tek_Ceiling.png", "Tek Ceiling"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 50 },
      { item: getItem("Polymer"), quantity: 25 },
      { item: getItem("Crystal"), quantity: 20 },
      { item: getItem("Element"), quantity: 1 },
    ],
  },  
  {
    value: "Tek Chestpiece",
    label: getRes("Tek_Chestpiece.png", "Tek Chestpiece"),
    recipe: [
      { item: getItem("Polymer"), quantity: 120 },
      { item: getItem("Metal Ingot"), quantity: 450 },
      { item: getItem("Crystal"), quantity: 120 },
      { item: getItem("Element"), quantity: 20 },
      { item: getItem("Black Pearl"), quantity: 55 },
    ],
  },  
  {
    value: "Tek Claws",
    label: getRes("Tek_Claws.png", "Tek Claws"),
    recipe: [
      { item: getItem("Polymer"), quantity: 160 },
      { item: getItem("Metal Ingot"), quantity: 180 },
      { item: getItem("Crystal"), quantity: 184 },
      { item: getItem("Element"), quantity: 28 },
      { item: getItem("Black Pearl"), quantity: 52 },
    ],
  },  
  {
    value: "Tek Crop Plot",
    label: getRes("Tek_Crop_Plot.png", "Tek Crop Plot"),
    recipe: [
      { item: getItem("Element"), quantity: 3 },
      { item: getItem("Metal Ingot"), quantity: 125 },
      { item: getItem("Polymer"), quantity: 55 },
      { item: getItem("Crystal"), quantity: 75 },
      { item: getItem("Sand"), quantity: 50 },
      { item: getItem("Black Pearl"), quantity: 4 },
    ],
  },  
  {
    value: "Tek Dedicated Storage",
    label: getRes("Tek_Dedicated_Storage.png", "Tek Dedicated Storage"),
    recipe: [
      { item: getItem("Black Pearl"), quantity: 4 },
      { item: getItem("Crystal"), quantity: 75 },
      { item: getItem("Element"), quantity: 3 },
      { item: getItem("Metal Ingot"), quantity: 125 },
      { item: getItem("Polymer"), quantity: 55 },
    ],
  },  
  {
    value: "Tek Dinosaur Gate",
    label: getRes("Tek_Dinosaur_Gate.png", "Tek Dinosaur Gate"),
    recipe: [
      { item: getItem("Element"), quantity: 2 },
      { item: getItem("Polymer"), quantity: 30 },
      { item: getItem("Crystal"), quantity: 35 },
    ],
  },  
  {
    value: "Tek Dinosaur Gateway",
    label: getRes("Tek_Dinosaur_Gateway.png", "Tek Dinosaur Gateway"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 170 },
      { item: getItem("Polymer"), quantity: 50 },
      { item: getItem("Crystal"), quantity: 30 },
      { item: getItem("Element"), quantity: 2 },
    ],
  },  
  {
    value: "Tek Door",
    label: getRes("Tek_Door.png", "Tek Door"),
    recipe: [
      { item: getItem("Polymer"), quantity: 10 },
      { item: getItem("Crystal"), quantity: 15 },
      { item: getItem("Element"), quantity: 0.25 },
    ],
  },  
  {
    value: "Tek Doorframe",
    label: getRes("Tek_Doorframe.png", "Tek Doorframe"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 30 },
      { item: getItem("Polymer"), quantity: 15 },
      { item: getItem("Crystal"), quantity: 5 },
      { item: getItem("Element"), quantity: 1 },
    ],
  },  
  {
    value: "Tek Double Door",
    label: getRes("Tek_Double_Door.png", "Tek Double Door"),
    recipe: [
      { item: getItem("Crystal"), quantity: 30 },
      { item: getItem("Element"), quantity: 0.5 },
      { item: getItem("Polymer"), quantity: 20 },
    ],
  },  
  {
    value: "Tek Double Doorframe",
    label: getRes("Tek_Double_Doorframe.png", "Tek Double Doorframe"),
    recipe: [
      { item: getItem("Crystal"), quantity: 5 },
      { item: getItem("Element"), quantity: 1 },
      { item: getItem("Metal Ingot"), quantity: 30 },
      { item: getItem("Polymer"), quantity: 15 },
    ],
  },  
  {
    value: "Tek Fence Foundation",
    label: getRes("Tek_Fence_Foundation.png", "Tek Fence Foundation"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Cementing Paste"), quantity: 2 },
      { item: getItem("Element Shard"), quantity: 25 },
    ],
  },  
  {
    value: "Tek Fence Support",
    label: getRes("Tek_Fence_Support.png", "Tek Fence Support"),
    recipe: [
      { item: getItem("Cementing Paste"), quantity: 2 },
      { item: getItem("Element Shard"), quantity: 25 },
      { item: getItem("Metal Ingot"), quantity: 5 },
    ],
  },  
  {
    value: "Tek Forcefield",
    label: getRes("Tek_Forcefield.png", "Tek Forcefield"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5000 },
      { item: getItem("Crystal"), quantity: 600 },
      { item: getItem("Black Pearl"), quantity: 150 },
      { item: getItem("Polymer"), quantity: 800 },
      { item: getItem("Element"), quantity: 75 },
    ],
  },  
  {
    value: "Tek Foundation",
    label: getRes("Tek_Foundation.png", "Tek Foundation"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 100 },
      { item: getItem("Polymer"), quantity: 45 },
      { item: getItem("Crystal"), quantity: 20 },
      { item: getItem("Element"), quantity: 1 },
    ],
  },  
  {
    value: "Tek Gauntlets",
    label: getRes("Tek_Gauntlets.png", "Tek Gauntlets"),
    recipe: [
      { item: getItem("Polymer"), quantity: 120 },
      { item: getItem("Metal Ingot"), quantity: 450 },
      { item: getItem("Crystal"), quantity: 120 },
      { item: getItem("Element"), quantity: 20 },
      { item: getItem("Black Pearl"), quantity: 55 },
    ],
  },  
  {
    value: "Tek Generator",
    label: getRes("Tek_Generator.png", "Tek Generator"),
    recipe: [
      { item: getItem("Element"), quantity: 30 },
      { item: getItem("Metal Ingot"), quantity: 2400 },
      { item: getItem("Crystal"), quantity: 250 },
      { item: getItem("Black Pearl"), quantity: 120 },
      { item: getItem("Polymer"), quantity: 500 },
    ],
  },  
  {
    value: "Tek Gravity Grenade",
    label: getRes("Tek_Gravity_Grenade.png", "Tek Gravity Grenade"),
    recipe: [
      { item: getItem("Element Dust"), quantity: 200 },
      { item: getItem("Crystal"), quantity: 50 },
      { item: getItem("Metal Ingot"), quantity: 30 },
      { item: getItem("Polymer"), quantity: 60 },
      { item: getItem("Gunpowder"), quantity: 60 },
    ],
  },  
  {
    value: "Tek Grenade",
    label: getRes("Tek_Grenade.png", "Tek Grenade"),
    recipe: [
      { item: getItem("Element"), quantity: 1 },
      { item: getItem("Crystal"), quantity: 40 },
      { item: getItem("Cementing Paste"), quantity: 10 },
      { item: getItem("Metal Ingot"), quantity: 30 },
      { item: getItem("Polymer"), quantity: 30 },
      { item: getItem("Gunpowder"), quantity: 60 },
    ],
  },  
  {
    value: "Tek Grenade Launcher",
    label: getRes("Tek_Grenade_Launcher.png", "Tek Grenade Launcher"),
    recipe: [
      { item: getItem("Polymer"), quantity: 180 },
      { item: getItem("Metal Ingot"), quantity: 420 },
      { item: getItem("Crystal"), quantity: 180 },
      { item: getItem("Element"), quantity: 40 },
      { item: getItem("Black Pearl"), quantity: 80 },
      { item: getItem("Electronics"), quantity: 60 },
    ],
  },  
  {
    value: "Tek Hatchframe",
    label: getRes("Tek_Hatchframe.png", "Tek Hatchframe"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 50 },
      { item: getItem("Polymer"), quantity: 25 },
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Element"), quantity: 1 },
    ],
  },  
  {
    value: "Tek Helmet",
    label: getRes("Tek_Helmet.png", "Tek Helmet"),
    recipe: [
      { item: getItem("Polymer"), quantity: 120 },
      { item: getItem("Metal Ingot"), quantity: 450 },
      { item: getItem("Crystal"), quantity: 120 },
      { item: getItem("Element"), quantity: 20 },
      { item: getItem("Black Pearl"), quantity: 55 },
    ],
  },  
  {
    value: "Tek Jump Pad",
    label: getRes("Tek_Jump_Pad.png", "Tek Jump Pad"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 100 },
      { item: getItem("Element"), quantity: 5 },
      { item: getItem("Polymer"), quantity: 250 },
      { item: getItem("Crystal"), quantity: 50 },
    ],
  },  
  {
    value: "Tek Ladder",
    label: getRes("Tek_Ladder.png", "Tek Ladder"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 7 },
      { item: getItem("Polymer"), quantity: 5 },
      { item: getItem("Crystal"), quantity: 2 },
      { item: getItem("Element"), quantity: 0.25 },
    ],
  },  
  {
    value: "Tek Leggings",
    label: getRes("Tek_Leggings.png", "Tek Leggings"),
    recipe: [
      { item: getItem("Polymer"), quantity: 120 },
      { item: getItem("Metal Ingot"), quantity: 450 },
      { item: getItem("Crystal"), quantity: 120 },
      { item: getItem("Element"), quantity: 20 },
      { item: getItem("Black Pearl"), quantity: 55 },
    ],
  },  
  {
    value: "Tek Light",
    label: getRes("Tek_Light.png", "Tek Light"),
    recipe: [
      { item: getItem("Element"), quantity: 2 },
      { item: getItem("Metal Ingot"), quantity: 90 },
      { item: getItem("Crystal"), quantity: 20 },
      { item: getItem("Black Pearl"), quantity: 1 },
      { item: getItem("Polymer"), quantity: 40 },
    ],
  },  
  {
    value: "Tek Phase Pistol",
    label: getRes("Tek_Phase_Pistol.png", "Tek Phase Pistol"),
    recipe: [
      { item: getItem("Polymer"), quantity: 70 },
      { item: getItem("Metal Ingot"), quantity: 300 },
      { item: getItem("Crystal"), quantity: 70 },
      { item: getItem("Element"), quantity: 15 },
      { item: getItem("Black Pearl"), quantity: 35 },
      { item: getItem("Red Gem"), quantity: 15 },
      { item: getItem("Blue Gem"), quantity: 15 },
      { item: getItem("Green Gem"), quantity: 15 },
    ],
  },  
  {
    value: "Tek Pillar",
    label: getRes("Tek_Pillar.png", "Tek Pillar"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 30 },
      { item: getItem("Polymer"), quantity: 7 },
      { item: getItem("Crystal"), quantity: 5 },
      { item: getItem("Element"), quantity: 0.5 },
    ],
  },  
  {
    value: "Tek Railgun",
    label: getRes("Tek_Railgun.png", "Tek Railgun"),
    recipe: [
      { item: getItem("Polymer"), quantity: 450 },
      { item: getItem("Metal Ingot"), quantity: 120 },
      { item: getItem("Gas Ball"), quantity: 525 },
      { item: getItem("Element"), quantity: 20 },
      { item: getItem("Black Pearl"), quantity: 55 },
      { item: getItem("Red Gem"), quantity: 120 },
    ],
  },  
  {
    value: "Tek Railing",
    label: getRes("Tek_Railing.png", "Tek Railing"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 12 },
      { item: getItem("Cementing Paste"), quantity: 3 },
      { item: getItem("Element Shard"), quantity: 25 },
    ],
  },  
  {
    value: "Tek Ramp",
    label: getRes("Tek_Ramp.png", "Tek Ramp"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Polymer"), quantity: 10 },
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Element"), quantity: 0.5 },
    ],
  },  
  {
    value: "Tek Replicator",
    label: getRes("Tek_Replicator.png", "Tek Replicator"),
    recipe: [
      { item: getItem("Element"), quantity: 100 },
      { item: getItem("Metal Ingot"), quantity: 5000 },
      { item: getItem("Crystal"), quantity: 600 },
      { item: getItem("Black Pearl"), quantity: 150 },
      { item: getItem("Polymer"), quantity: 800 },
    ],
  },  
  {
    value: "Tek Remote Camera",
    label: getRes("Tek_Remote_Camera.png", "Tek Remote Camera"),
    recipe: [
      { item: getItem("Polymer"), quantity: 10 },
      { item: getItem("Metal Ingot"), quantity: 80 },
      { item: getItem("Electronics"), quantity: 25 },
      { item: getItem("Element"), quantity: 1 },
      { item: getItem("Crystal"), quantity: 25 },
    ],
  },  
  {
    value: "Tek Rifle",
    label: getRes("Tek_Rifle.png", "Tek Rifle"),
    recipe: [
      { item: getItem("Polymer"), quantity: 120 },
      { item: getItem("Metal Ingot"), quantity: 450 },
      { item: getItem("Crystal"), quantity: 120 },
      { item: getItem("Element"), quantity: 20 },
      { item: getItem("Black Pearl"), quantity: 55 },
    ],
  },  
  {
    value: "Tek Sensor",
    label: getRes("Tek_Sensor.png", "Tek Sensor"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 80 },
      { item: getItem("Electronics"), quantity: 15 },
      { item: getItem("Polymer"), quantity: 10 },
      { item: getItem("Crystal"), quantity: 22 },
      { item: getItem("Element"), quantity: 5 },
    ],
  },  

  {
    value: "Tek Shield",
    label: getRes("Tek_Shield.png", "Tek Shield"),
    recipe: [
      { item: getItem("Polymer"), quantity: 240 },
      { item: getItem("Metal Ingot"), quantity: 900 },
      { item: getItem("Crystal"), quantity: 240 },
      { item: getItem("Element"), quantity: 70 },
      { item: getItem("Black Pearl"), quantity: 100 },
    ],
  },  
  {
    value: "Tek Shoulder Cannon",
    label: getRes("Tek_Shoulder_Cannon.png", "Tek Shoulder Cannon"),
    recipe: [
      { item: getItem("Polymer"), quantity: 240 },
      { item: getItem("Metal Ingot"), quantity: 900 },
      { item: getItem("Crystal"), quantity: 240 },
      { item: getItem("Element"), quantity: 100 },
      { item: getItem("Black Pearl"), quantity: 100 },
    ],
  },  
  {
    value: "Tek Sleeping Pod",
    label: getRes("Tek_Sleeping_Pod.png", "Tek Sleeping Pod"),
    recipe: [
      { item: getItem("Polymer"), quantity: 80 },
      { item: getItem("Pelt"), quantity: 200 },
      { item: getItem("Metal Ingot"), quantity: 320 },
      { item: getItem("Hide"), quantity: 120 },
      { item: getItem("Fiber"), quantity: 280 },
    ],
  },  
  {
    value: "Tek Staircase",
    label: getRes("Tek_Staircase.png", "Tek Staircase"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 125 },
      { item: getItem("Cementing Paste"), quantity: 35 },
      { item: getItem("Element Shard"), quantity: 50 },
    ],
  },  
  {
    value: "Tek Stairs",
    label: getRes("Tek_Stairs.png", "Tek Stairs"),
    recipe: [
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Element"), quantity: 0.5 },
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Polymer"), quantity: 10 },
    ],
  },
    
  {
    value: "Tek Surveillance Console",
    label: getRes("Tek_Surveillance_Console.png", "Tek Surveillance Console"),
    recipe: [
      { item: getItem("Polymer"), quantity: 250 },
      { item: getItem("Metal Ingot"), quantity: 250 },
      { item: getItem("Electronics"), quantity: 500 },
      { item: getItem("Element"), quantity: 10 },
      { item: getItem("Black Pearl"), quantity: 50 },
    ],
  },    
  {
    value: "Tek Sword",
    label: getRes("Tek_Sword.png", "Tek Sword"),
    recipe: [
      { item: getItem("Polymer"), quantity: 180 },
      { item: getItem("Metal Ingot"), quantity: 675 },
      { item: getItem("Crystal"), quantity: 180 },
      { item: getItem("Element"), quantity: 40 },
      { item: getItem("Black Pearl"), quantity: 80 },
    ],
  },    
  {
    value: "Tek Teleporter",
    label: getRes("Tek_Teleporter.png", "Tek Teleporter"),
    recipe: [
      { item: getItem("Element"), quantity: 85 },
      { item: getItem("Metal Ingot"), quantity: 2500 },
      { item: getItem("Crystal"), quantity: 600 },
      { item: getItem("Black Pearl"), quantity: 150 },
      { item: getItem("Polymer"), quantity: 1200 },
    ],
  },    
  {
    value: "Tek Transmitter",
    label: getRes("Tek_Transmitter.png", "Tek Transmitter"),
    recipe: [
      { item: getItem("Element"), quantity: 40 },
      { item: getItem("Metal Ingot"), quantity: 2500 },
      { item: getItem("Crystal"), quantity: 250 },
      { item: getItem("Black Pearl"), quantity: 80 },
      { item: getItem("Polymer"), quantity: 320 },
    ],
  },    
  {
    value: "Tek Trapdoor",
    label: getRes("Tek_Trapdoor.png", "Tek Trapdoor"),
    recipe: [
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Polymer"), quantity: 4 },
      { item: getItem("Element"), quantity: 0.25 },
    ],
  },    
  {
    value: "Tek Triangle Ceiling",
    label: getRes("Tek_Triangle_Ceiling.png", "Tek Triangle Ceiling"),
    recipe: [
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Element Shard"), quantity: 50 },
      { item: getItem("Metal Ingot"), quantity: 25 },
      { item: getItem("Polymer"), quantity: 12 },
    ],
  },    
  {
    value: "Tek Triangle Foundation",
    label: getRes("Tek_Triangle_Foundation.png", "Tek Triangle Foundation"),
    recipe: [
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Element Shard"), quantity: 50 },
      { item: getItem("Metal Ingot"), quantity: 50 },
      { item: getItem("Polymer"), quantity: 20 },
    ],
  },    
  {
    value: "Tek Trough",
    label: getRes("Tek_Trough.png", "Tek Trough"),
    recipe: [
      { item: getItem("Black Pearl"), quantity: 45 },
      { item: getItem("Crystal"), quantity: 100 },
      { item: getItem("Electronics"), quantity: 50 },
      { item: getItem("Element"), quantity: 18 },
      { item: getItem("Metal Ingot"), quantity: 900 },
      { item: getItem("Polymer"), quantity: 175 },
    ],
  },    
  {
    value: "Tek Turret",
    label: getRes("Tek_Turret.png", "Tek Turret"),
    recipe: [
      { item: getItem("Electronics"), quantity: 100 },
      { item: getItem("Metal Ingot"), quantity: 100 },
      { item: getItem("Cementing Paste"), quantity: 50 },
      { item: getItem("Polymer"), quantity: 50 },
      { item: getItem("Element"), quantity: 3 },
    ],
  },    
  {
    value: "Tek Wall",
    label: getRes("Tek_Wall.png", "Tek Wall"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 35 },
      { item: getItem("Polymer"), quantity: 20 },
      { item: getItem("Crystal"), quantity: 15 },
      { item: getItem("Element"), quantity: 1 },
    ],
  },    
  {
    value: "Toilet",
    label: getRes("Toilet.png", "Toilet"),
    recipe: [
      { item: getItem("Stone"), quantity: 30 },
      { item: getItem("Wood"), quantity: 70 },
      { item: getItem("Metal"), quantity: 180 },
      { item: getItem("Cementing Paste"), quantity: 200 },
      { item: getItem("Crystal"), quantity: 40 },
    ],
  },    
  {
    value: "Tranq Arrow",
    label: getRes("Tranq_Arrow.png", "Tranq Arrow"),
    recipe: [
      { item: getItem("Stone Arrow"), quantity: 1 },
      { item: getItem("Narcotic"), quantity: 1 },
    ],
  },    
  {
    value: "Tranquilizer Dart",
    label: getRes("Tranquilizer_Dart.png", "Tranquilizer Dart"),
    recipe: [
      { item: getItem("Narcotic"), quantity: 3 },
      { item: getItem("Metal Ingot"), quantity: 2 },
      { item: getItem("Simple Rifle Ammo"), quantity: 1 },
    ],
  },    
  {
    value: "Tree Sap Tap",
    label: getRes("Tree_Sap_Tap.png", "Tree Sap Tap"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 100 },
      { item: getItem("Cementing Paste"), quantity: 40 },
    ],
  },    
  {
    value: "Unassembled Mek",
    label: getRes("Unassembled_Mek.png", "Unassembled Mek"),
    recipe: [
      { item: getItem("Black Pearl"), quantity: 25 },
      { item: getItem("Crystal"), quantity: 225 },
      { item: getItem("Element"), quantity: 40 },
      { item: getItem("Metal Ingot"), quantity: 1000 },
      { item: getItem("Polymer"), quantity: 550 },
    ],
  },    
  {
    value: "Exo Mek",
    label: getRes("Unassembled_Exo-Mek.png", "Exo Mek"),
    recipe: [
      { item: getItem("Element"), quantity: 40 },
      { item: getItem("Metal Ingot"), quantity: 850 },
      { item: getItem("Polymer"), quantity: 300 },
      { item: getItem("Crystal"), quantity: 350 },
      { item: getItem("Black Pearl"), quantity: 35 },
    ],
  },    
  {
    value: "TEK Hover Skiff",
    label: getRes("Unassembled_TEK_Hover_Skiff.png", "TEK Hover Skiff"),
    recipe: [
      { item: getItem("Element"), quantity: 168 },
      { item: getItem("Metal Ingot"), quantity: 4100 },
      { item: getItem("Polymer"), quantity: 372 },
      { item: getItem("Crystal"), quantity: 60 },
      { item: getItem("Oil"), quantity: 62 },
      { item: getItem("Electronics"), quantity: 268 },
    ],
  },    
  {
    value: "Vacuum Compartment",
    label: getRes("Vacuum_Compartment.png", "Vacuum Compartment"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 360 },
      { item: getItem("Polymer"), quantity: 136 },
      { item: getItem("Crystal"), quantity: 180 },
      { item: getItem("Element"), quantity: 2 },
      { item: getItem("Black Pearl"), quantity: 32 },
    ],
  },    
  {
    value: "Vacuum Compartment Moonpool",
    label: getRes("Vacuum_Compartment_Moonpool.png", "Vacuum Compartment Moonpool"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 360 },
      { item: getItem("Polymer"), quantity: 136 },
      { item: getItem("Crystal"), quantity: 180 },
      { item: getItem("Element"), quantity: 2 },
      { item: getItem("Black Pearl"), quantity: 32 },
    ],
  },    
  {
    value: "Vault",
    label: getRes("Vault.png", "Vault"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 500 },
      { item: getItem("Cementing Paste"), quantity: 100 },
      { item: getItem("Oil"), quantity: 70 },
      { item: getItem("Polymer"), quantity: 90 },
    ],
  },    
  {
    value: "Water Jar",
    label: getRes("Water_Jar.png", "Water Jar"),
    recipe: [
      { item: getItem("Cementing Paste"), quantity: 7 },
      { item: getItem("Hide"), quantity: 5 },
      { item: getItem("Crystal"), quantity: 2 },
    ],
  },    
  {
    value: "Whip",
    label: getRes("Whip.png", "Whip"),
    recipe: [
      { item: getItem("Hide"), quantity: 10 },
      { item: getItem("Wood"), quantity: 5 },
      { item: getItem("Fiber"), quantity: 25 },
      { item: getItem("Silk"), quantity: 10 },
    ],
  },
  
  
  {
    value: "Beer Liquid",
    label: getRes("Beer_Liquid.png", "Beer Liquid"),
    recipe: [
      { item: getItem("Thatch"), quantity: 40 },
      { item: getItem("Azulberry"), quantity: 50 },
    ],
  },
  {
    value: "Calien Soup",
    label: getRes("Calien_Soup.png", "Calien Soup"),
    recipe: [
      { item: getItem("Citronal"), quantity: 5 },
      { item: getItem("Tintoberry"), quantity: 20 },
      { item: getItem("Amarberry"), quantity: 20 },
      { item: getItem("Mejoberry"), quantity: 10 },
      { item: getItem("Stimulant"), quantity: 2 },
    ],
  },
  {
    value: "Cactus Broth",
    label: getRes("Cactus_Broth.png", "Cactus Broth"),
    recipe: [
      { item: getItem("Cactus Sap"), quantity: 30 },
      { item: getItem("Mejoberry"), quantity: 10 },
      { item: getItem("Azulberry"), quantity: 10 },
      { item: getItem("Amarberry"), quantity: 10 },
    ],
  },
  {
    value: "Amarberry",
    label: getRes("Amarberry.png", "Amarberry"),
    recipe: [
      { item: getItem("Amarberry"), quantity: 1 },
    ],
  },
  {
    value: "Ammonite Blood",
    label: getRes("Ammonite_Bile.png", "Ammonite Blood"),
    recipe: [
      { item: getItem("Ammonite Blood"), quantity: 1 },
    ],
  },
  {
    value: "Azulberry",
    label: getRes("Azulberry.png", "Azulberry"),
    recipe: [
      { item: getItem("Azulberry"), quantity: 1 },
    ],
  },
  {
    value: "Black Pearl",
    label: getRes("Black_Pearl.png", "Black Pearl"),
    recipe: [
      { item: getItem("Black Pearl"), quantity: 1 },
    ],
  },
  {
    value: "Blue Gem",
    label: getRes("Blue_Gem.png", "Blue Gem"),
    recipe: [
      { item: getItem("Blue Gem"), quantity: 1 },
    ],
  },
  {
    value: "Cactus Sap",
    label: getRes("Cactus_Sap.png", "Cactus Sap"),
    recipe: [
      { item: getItem("Cactus Sap"), quantity: 1 },
    ],
  },
  {
    value: "Cementing Paste (Hexagon)",
    label: getRes("Cementing_Paste.png", "Cementing Paste (Hexagon)"),
    recipe: [
      { item: getItem("Cementing Paste"), quantity: 1 },
    ],
  },
  {
    value: "Cementing Paste (Chemistry bench)",
    label: getRes("Cementing_Paste.png", "Cementing Paste (Chemistry bench)"),
    recipe: [
      { item: getItem("Keratin"), quantity: 2.6666 },
      { item: getItem("Stone"), quantity: 5.3333 },
    ],
  },
  {
    value: "Charcoal",
    label: getRes("Charcoal.png", "Charcoal"),
    recipe: [
      { item: getItem("Charcoal"), quantity: 1 },
    ],
  },
  {
    value: "Chitin",
    label: getRes("Chitin.png", "Chitin"),
    recipe: [
      { item: getItem("Chitin"), quantity: 1 },
    ],
  },
  {
    value: "Clay",
    label: getRes("Clay.png", "Clay"),
    recipe: [
      { item: getItem("Clay"), quantity: 1 },
    ],
  },
  {
    value: "Crystal",
    label: getRes("Crystal.png", "Crystal"),
    recipe: [
      { item: getItem("Crystal"), quantity: 1 },
    ],
  },
  {
    value: "Electronics",
    label: getRes("Electronics.png", "Electronics"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 1 },
      { item: getItem("Silica Pearls"), quantity: 3 },
    ],
  },
  {
    value: "Electronics (Hexagon)",
    label: getRes("Electronics.png", "Electronics (Hexagon)"),
    recipe: [
      { item: getItem("Electronics"), quantity: 1 },
    ],
  },
  {
    value: "Fiber",
    label: getRes("Fiber.png", "Fiber"),
    recipe: [
      { item: getItem("Fiber"), quantity: 1 },
    ],
  },
  {
    value: "Flint",
    label: getRes("Flint.png", "Flint"),
    recipe: [
      { item: getItem("Flint"), quantity: 1 },
    ],
  },
  {
    value: "Gasoline (Hexagon)",
    label: getRes("Gasoline.png", "Gasoline (Hexagon)"),
    recipe: [
      { item: getItem("Gas"), quantity: 1 },
    ],
  },
  {
    value: "Green Gem",
    label: getRes("Green_Gem.png", "Green Gem"),
    recipe: [
      { item: getItem("Green Gem"), quantity: 1 },
    ],
  },
  {
    value: "Gunpowder (Hexagon)",
    label: getRes("Gunpowder.png", "Gunpowder (Hexagon)"),
    recipe: [
      { item: getItem("Gunpowder"), quantity: 1 },
    ],
  },
  {
    value: "Hide",
    label: getRes("Hide.png", "Hide"),
    recipe: [
      { item: getItem("Hide"), quantity: 1 },
    ],
  },
  {
    value: "Keratin",
    label: getRes("Keratin.png", "Keratin"),
    recipe: [
      { item: getItem("Keratin"), quantity: 1 },
    ],
  },
  {
    value: "Mejoberry",
    label: getRes("Mejoberry.png", "Mejoberry"),
    recipe: [
      { item: getItem("Mejoberry"), quantity: 1 },
    ],
  },
  {
    value: "Metal",
    label: getRes("Metal.png", "Metal"),
    recipe: [
      { item: getItem("Metal"), quantity: 1 },
    ],
  },
  {
    value: "Metal Ingot",
    label: getRes("Metal_Ingot.png", "Metal Ingot"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 1 },
    ],
  },
  {
    value: "Narcoberry",
    label: getRes("Narcoberry.png", "Narcoberry"),
    recipe: [
      { item: getItem("Narcoberry"), quantity: 1 },
    ],
  },
  {
    value: "Obsidian",
    label: getRes("Obsidian.png", "Obsidian"),
    recipe: [
      { item: getItem("Obsidian"), quantity: 1 },
    ],
  },
  {
    value: "Oil",
    label: getRes("Oil.png", "Oil"),
    recipe: [
      { item: getItem("Oil"), quantity: 1 },
    ],
  },
  {
    value: "Polymer (Hexagon)",
    label: getRes("Organic_Polymer.png", "Polymer (Hexagon)"),
    recipe: [
      { item: getItem("Polymer"), quantity: 1 },
    ],
  },
  {
    value: "Pelt",
    label: getRes("Pelt.png", "Pelt"),
    recipe: [
      { item: getItem("Pelt"), quantity: 1 },
    ],
  },
  {
    value: "Propellant (Hexagon)",
    label: getRes("Propellant.png", "Propellant"),
    recipe: [
      { item: getItem("Propellant"), quantity: 1 },
    ],
  },
  {
    value: "Rare Flowers",
    label: getRes("Rare_Flower.png", "Rare Flowers"),
    recipe: [
      { item: getItem("Rare Flowers"), quantity: 1 },
    ],
  },
  {
    value: "Rare Mushrooms",
    label: getRes("Rare_Mushroom.png", "Rare Mushrooms"),
    recipe: [
      { item: getItem("Rare Mushrooms"), quantity: 1 },
    ],
  },
  {
    value: "Raw Meat",
    label: getRes("Raw_Meat.png", "Raw Meat"),
    recipe: [
      { item: getItem("Raw Meat"), quantity: 1 },
    ],
  },
  {
    value: "Red Gem",
    label: getRes("Red_Gem.png", "Red Gem"),
    recipe: [
      { item: getItem("Red Gem"), quantity: 1 },
    ],
  },
  {
    value: "Salt",
    label: getRes("Salt.png", "Salt"),
    recipe: [
      { item: getItem("Salt"), quantity: 1 },
    ],
  },
  {
    value: "Sand",
    label: getRes("Sand.png", "Sand"),
    recipe: [
      { item: getItem("Sand"), quantity: 1 },
    ],
  },
  {
    value: "Sap",
    label: getRes("Sap.png", "Sap"),
    recipe: [
      { item: getItem("Sap"), quantity: 1 },
    ],
  },
  {
    value: "Silica Pearls",
    label: getRes("Silica_Pearls.png", "Silica Pearls"),
    recipe: [
      { item: getItem("Silica Pearls"), quantity: 1 },
    ],
  },
  {
    value: "Silk",
    label: getRes("Silk.png", "Silk"),
    recipe: [
      { item: getItem("Silk"), quantity: 1 },
    ],
  },
  {
    value: "Sparkpowder (Hexagon)",
    label: getRes("Sparkpowder.png", "Sparkpowder (Hexagon)"),
    recipe: [
      { item: getItem("Sparkpowder"), quantity: 1 },
    ],
  },
  {
    value: "Stimberry",
    label: getRes("Stimberry.png", "Stimberry"),
    recipe: [
      { item: getItem("Stimberry"), quantity: 1 },
    ],
  },
  {
    value: "Stone",
    label: getRes("Stone.png", "Stone"),
    recipe: [
      { item: getItem("Stone"), quantity: 1 },
    ],
  },
  {
    value: "Sulfur",
    label: getRes("Sulfur.png", "Sulfur"),
    recipe: [
      { item: getItem("Sulfur"), quantity: 1 },
    ],
  },
  {
    value: "Thatch",
    label: getRes("Thatch.png", "Thatch"),
    recipe: [
      { item: getItem("Thatch"), quantity: 1 },
    ],
  },
  {
    value: "Tintoberry",
    label: getRes("Tintoberry.png", "Tintoberry"),
    recipe: [
      { item: getItem("Tintoberry"), quantity: 1 },
    ],
  },
  {
    value: "Wood",
    label: getRes("Wood.png", "Wood"),
    recipe: [
      { item: getItem("Wood"), quantity: 1 },
    ],
  },
  
    {
    value: "Battle Tartare",
    label: getRes("Battle_Tartare.png", "Battle Tartare"),
    recipe: [
      { item: getItem("Raw Prime Meat"), quantity: 3 },
      { item: getItem("Mejoberry"), quantity: 20 },
      { item: getItem("Stimulant"), quantity: 8 },
      { item: getItem("Rare Flowers"), quantity: 2 },
      { item: getItem("Citronal"), quantity: 1 },
      { item: getItem("Longrass"), quantity: 1 },
    ],
  },

  {
    value: "Enforcer",
    label: getRes("Enforcer.png", "Enforcer"),
    recipe: [
      { item: getItem("Element Dust"), quantity: 500 },
      { item: getItem("Metal Ingot"), quantity: 60 },
      { item: getItem("Polymer"), quantity: 30 },
      { item: getItem("Crystal"), quantity: 50 },
      { item: getItem("Oil"), quantity: 20 },
    ],
  },

  {
    value: "Harpoon Launcher",
    label: getRes("Harpoon_Launcher.png", "Harpoon Launcher"),
    recipe: [
      { item: getItem("Wood"), quantity: 40 },
      { item: getItem("Fiber"), quantity: 60 },
      { item: getItem("Hide"), quantity: 25 },
      { item: getItem("Metal Ingot"), quantity: 120 },
      { item: getItem("Cementing Paste"), quantity: 60 },
      { item: getItem("Obsidian"), quantity: 50 },
      { item: getItem("Polymer"), quantity: 30 },
    ],
  },

  {
    value: "Greenhouse Wall",
    label: getRes("Greenhouse_Wall.png", "Greenhouse Wall"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 7 },
      { item: getItem("Crystal"), quantity: 25 },
      { item: getItem("Cementing Paste"), quantity: 7 },
    ],
  },
  {
    value: "Greenhouse Sloped Wall Left",
    label: getRes("Greenhouse_Sloped_Wall_Left.png", "Greenhouse Sloped Wall Left"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 4 },
      { item: getItem("Crystal"), quantity: 15 },
      { item: getItem("Cementing Paste"), quantity: 4 },
    ],
  },
  {
    value: "Greenhouse Sloped Wall Right",
    label: getRes("Greenhouse_Sloped_Wall_Right.png", "Greenhouse Sloped Wall Right"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 4 },
      { item: getItem("Crystal"), quantity: 15 },
      { item: getItem("Cementing Paste"), quantity: 4 },
    ],
  },
  {
    value: "Greenhouse Doorframe",
    label: getRes("Greenhouse_Doorframe.png", "Greenhouse Doorframe"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Crystal"), quantity: 20 },
      { item: getItem("Cementing Paste"), quantity: 5 },
    ],
  },
  {
    value: "Greenhouse Double Doorframe",
    label: getRes("Greenhouse_Double_Doorframe.png", "Greenhouse Double Doorframe"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Crystal"), quantity: 20 },
      { item: getItem("Cementing Paste"), quantity: 5 },
    ],
  },
  {
    value: "Greenhouse Door",
    label: getRes("Greenhouse_Door.png", "Greenhouse Door"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 3 },
      { item: getItem("Crystal"), quantity: 10 },
      { item: getItem("Cementing Paste"), quantity: 4 },
    ],
  },
  {
    value: "Greenhouse Double Door",
    label: getRes("Greenhouse_Double_Door.png", "Greenhouse Double Door"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 6 },
      { item: getItem("Crystal"), quantity: 20 },
      { item: getItem("Cementing Paste"), quantity: 8 },
    ],
  },
  {
    value: "Greenhouse Window",
    label: getRes("Greenhouse_Window.png", "Greenhouse Window"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 1 },
      { item: getItem("Crystal"), quantity: 3 },
      { item: getItem("Cementing Paste"), quantity: 1 },
    ],
  },
  {
    value: "Greenhouse Ceiling",
    label: getRes("Greenhouse_Ceiling.png", "Greenhouse Ceiling"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 10 },
      { item: getItem("Crystal"), quantity: 35 },
      { item: getItem("Cementing Paste"), quantity: 10 },
    ],
  },
  {
    value: "Greenhouse Triangle Ceiling",
    label: getRes("Greenhouse_Triangle_Ceiling.png", "Greenhouse Triangle Ceiling"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 5 },
      { item: getItem("Crystal"), quantity: 17 },
      { item: getItem("Cementing Paste"), quantity: 5 },
    ],
  },
  {
    value: "Greenhouse Sloped Roof",
    label: getRes("Greenhouse_Roof.png", "Greenhouse Sloped Roof"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 10 },
      { item: getItem("Crystal"), quantity: 35 },
      { item: getItem("Cementing Paste"), quantity: 10 },
    ],
  },
  {
    value: "Greenhouse Triangle Roof",
    label: getRes("Greenhouse_Triangle_Roof.png", "Greenhouse Triangle Roof"),
    recipe: [
      { item: getItem("Metal Ingot"), quantity: 7 },
      { item: getItem("Crystal"), quantity: 25 },
      { item: getItem("Cementing Paste"), quantity: 7 },
    ],
  },
  
];

export default itemsRecipe;
